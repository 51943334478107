<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Mis UAS</h1>
        </v-row>
        <v-row class="table-container">
          <v-table class="table-100">
            <thead>
              <tr>
                <th @click="sortByColumn('model.manufacture')">
                  <span>
                    Fabricante
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.manufacture' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.manufacture' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.model')">
                  <span>
                    Modelo
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.model' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.model' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('uas.sn')">
                  <span>
                    SN
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'uas.sn' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'uas.sn' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.mtom')">
                  <span>
                    MTOM (kg)
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.mtom' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.mtom' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.wingspan')">
                  <span>
                    Dim max (m)
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.wingspan' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.wingspan' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.speed')">
                  <span>
                    Vel max. (m/s)
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.speed' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.speed' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.configuration')">
                  <span>
                    Configuración
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.configuration' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.configuration' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.energy')">
                  <span>
                    Energía (J)
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.energy' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.energy' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.camera')">
                  <span>
                    Camara
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.camera' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.camera' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.fpv')">
                  <span>
                    FPV
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.fpv' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.fpv' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th @click="sortByColumn('model.class')">
                  <span>
                    Clase
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'model.class' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'model.class' && sortOrder === -1 }"></i>
                  </span>
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(uas, index) in pagedUASList" :key="index" class="tr1">
                <td>{{ uas.model.manufacture }}</td>
                <td>{{ uas.model.model }}</td>
                <td>{{ uas.sn }}</td>
                <td>{{ formatDecimals(uas.model.mtom) }}</td>
                <td>{{ formatDecimals(uas.model.wingspan) }}</td>
                <td>{{ uas.model.speed }}</td>
                <td>{{ uas.model.configuration }}</td>
                <td>{{ uas.model.energy }}</td>
                <td>{{ formatIcon(uas.model.camera) }}</td>
                <td>{{ formatIcon(uas.model.fpv) }}</td>
                <td>{{ uas.model.class }}</td>
                <td @click="removeUAS(uas.uasID)">
                  <i class="mdi mdi-trash-can"></i>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-row>
        <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>
        <v-row justify="end">
          <button class="btn" @click="openDialog">Añadir nuevo UAS</button>
        </v-row>
      </v-container>

      <!-- DIALOGO NUEVO UAS -->
      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="card">
          <v-container class="pa-40">
            <v-row>
              <v-col><h2>Añadir UAS</h2></v-col>
              <v-col class="text-right"><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select v-model="selectedManufacturer" @update:modelValue="fetchModels" :items="manufacturers" item-title="manufacture" item-value="manufacture" label="Fabricante" required></v-select>
              </v-col>
              <v-col>
                <v-select v-model="uas.modelID" :items="models" label="Modelo" item-title="model" item-value="modelID" required></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="uas.sn" label="SN" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn @click="createUAS" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </v-card>
      </v-dialog>

    </div>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import uasService from '@/services/uasService';
import operatorService from '@/services/operatorService';

export default {
  name: 'UASPage',
  computed: {
    ...mapState('auth', ['token', 'roleID']),

    totalPages() {
      return Math.ceil(this.uasList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedUASList() {
      return this.uasList.slice(this.startIndex, this.endIndex);
    },
  },
  data() {
    return {
      uasList: [],
      dialogVisible: false,
      selectedManufacturer: null,
      selectedModel: null,

      uas:{
        modelID: '',
        sn:'',
      },
      manufacturers: [],
      models: [],

      errorMessage: '',

      pagination: {
        rowsPerPage: 10,
        page: 1
      },

      sortColumn: '',
      sortOrder: 1, 
    
    };
  },
  mounted() {


    this.fetchUAS();
    this.fetchManufacturers();
  },
  methods: {
    ...mapActions('auth',["logOut"]),

    /* 
    ------- UAS -------
    fetchUAS()
    fetchModels()
    fetchManufacturers()
    createUAS()    
    removeUAS()

    ------- Formatos -------
    formatDecimals(value)
    formatIcon(value)

    ------- Dialogos -------
    openDialog()
    closeDialog()

    ------- Orden y Navegación Tabla -------
    sortByColumn()
    nextPage()
    previousPage()

    */

    // ------------------------------------ UAS ------------------------------------
    async fetchUAS() {
      try {
        this.uasList = await operatorService.getUas(this.token, this.roleID);
        
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso');
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error('Error al obtener datos de UAS:', error);
        }
      }
    },

    async fetchModels() {
      try {
        this.models = await uasService.getModelsManufacture(this.token, this.selectedManufacturer);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso');
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error('Error al obtener la lista de Modelos:', error);
        }
      }
    },
    
    async fetchManufacturers() {
      try {
        this.manufacturers = await uasService.getAllManufactures(this.token);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso');
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error('Error al obtener fabricantes:', error);
        }
      }
    },

    async createUAS() {
      try {
        const response = await operatorService.createUas(this.token, this.roleID, this.uas);

        if (response == 201) {
          this.dialogVisible = false;
          this.fetchUAS();
        }
      } catch (error) {
        console.error('Error al agregar UAS:', error);
      }
    },
    
    async removeUAS(uasID) {
      try {
        await operatorService.removeUas(this.token, uasID);
        this.fetchUAS();
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso');
          this.$router.push("/login");
        } else {
          console.error('Error al eliminar el uas:', error);
        }
      }
    },
    // --------------------------------- Formatos  ---------------------------------
    formatDecimals(value) {
      const number = Number(value);
      if (!isNaN(number)) {
        return number.toFixed(3);
      }
      return '';
    },

    formatIcon(value) {
      return value === 1 ? "✔" : "✘";
    },

    // --------------------------------- Dialogos  ---------------------------------
    openDialog(){
      this.dialogVisible = true;
      this.selectedManufacturer = null;
      this.selectedModel = null;
      this.sn ='';
    },

    closeDialog(){
      this.dialogVisible = false;
    },

    // -------------------------- Orden y Navegación Tabla -------------------------   
    async sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        this.sortOrder *= -1;
      } else {
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }

      this.uasList.sort((a, b) => {
        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },

    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
      }
    },

    previousPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
      }
    },

    


    
  }
};
</script>


<style scoped>

</style>

