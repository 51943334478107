<template>
    <v-row>
      <v-parallax height="45vh" src="./../assets/images/takingoff.jpg">
        <div class="d-flex flex-column fill-height justify-center align-center text-white">
          <h1 class="text-h4 font-weight-thin mb-4">
            Docs4Drones
          </h1>
          <h4 class="subheading">
            La solución para tu documentación de vuelos
          </h4>
        </div>
      </v-parallax>
    </v-row>
    
  <v-container class="index-container">
    <v-row>
      <h2>¿Qué es Docs4Drones?</h2>
    </v-row>
    <v-row class="text-index">
      <p>Docs4Drones es una innovadora plataforma diseñada para facilitar y agilizar el tedioso proceso de obtener los permisos necesarios para operar drones.</p>
      <p class="espaciado-parrafos">Nos hemos comprometido a proporcionar una solución integral que simplifica cada paso, permitiéndote gestionar todos los aspectos relacionados con tus operaciones de drones de manera eficiente y sin complicaciones.</p>
      <p class="espaciado-parrafos">Con nuestra plataforma en línea, te ofrecemos un enfoque integral que abarca desde la solicitud inicial de permisos hasta la gestión continua de la documentación necesaria. Ya no tendrás que lidiar con los complicados trámites burocráticos, ya que Docs4Drones automatiza gran parte del proceso, ahorrándote tiempo y esfuerzo.</p>
      <p class="espaciado-parrafos">Además de simplificar la obtención de permisos de vuelo, Docs4Drones te brinda herramientas adicionales para optimizar tus operaciones. Puedes acceder fácilmente a información relevante sobre regulaciones actualizadas, zonas restringidas y condiciones meteorológicas, todo desde un solo lugar. Esto te permite planificar y ejecutar tus vuelos de manera más segura y eficiente.</p>
      <p class="espaciado-parrafos">Nos enorgullece ofrecer una solución completa que no solo agiliza el proceso de obtención de permisos, sino que también mejora la seguridad y eficiencia de tus operaciones con drones. Con Docs4Drones, puedes concentrarte en lo que realmente importa: aprovechar al máximo las capacidades de tu dron sin preocuparte por la complejidad administrativa.</p>
    </v-row>
  </v-container>
  <div  class="banner-red">
  <v-row>
    <v-col class="text-center">
      <h1>¿Por qué Docs4Drones?</h1>
    </v-col>
  </v-row>
  <v-row class="cols-index">
    <v-col class="text-center">
      <h2>Experiencia</h2>
      <i class="mdi mdi-chart-line-variant ic-index"></i>
    </v-col>
    <v-col class="text-center">
      <h2>Cero preocupaciones</h2>
      <i class="mdi mdi-thought-bubble-outline ic-index"></i>
    </v-col>
    <v-col class="text-center">
      <h2>Automática</h2>
      <i class="mdi mdi-head-lightbulb-outline ic-index"></i>
    </v-col>
  </v-row>
</div>

<div>
  <v-row class="cols-index-2">
    <v-col>
      <v-img
      :width="500"
      src="./../assets/images/plandron.jpg"
    ></v-img>
    </v-col>
    <v-col class="align-self-center">
      <v-row class="row2">
        <h2>Es hora de simplificar</h2>
      </v-row>
      <v-row style="width: 70%;" class="row2">
        <p>¿Suena bien no? ¡Pues no pierdas más el tiempo y empieza a ganar tranquiladad! La app se encuentra en fase beta mientras añadimos todas las funciones pero ya puedes disfrutar de sus principales funciones y adelantarte al resto.</p>
      </v-row>
      <v-row class="row2">
        <v-btn>Solicitar demo</v-btn>
      </v-row>
    </v-col>
  </v-row>
</div>
  
</template>

<script>
export default {
  name: 'IndexPage',
  methods: {

  }
}
</script>

<style>
.banner-red{
  background-color: brown;
  width: 100vmax;
  margin:0px !important;
  color:white;
  padding: 60px;
}
.cols-index{
  margin:150px;
  margin-bottom: 0px;
}
.cols-index-2{
  padding:150px;
  padding-bottom: 0px;
  padding-top: 50px;
}
.row2{
  margin:20px !important;
}
.index-container{
  margin-top: 40px;
  margin-bottom: 40px;
  width:70%;
  background-color: white !important;
}

.ic-index{
  font-size: 5em;
}
.ic-index:hover{
  color:white;
}

.text-index{
  text-align: justify;
}
.espaciado-parrafos{
  margin-bottom: 15px;
}
</style>
