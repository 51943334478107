<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Mis Pilotos</h1>
        </v-row>
      <v-row class="table-container">
        <v-table  class="table-100">
          <thead>
            <tr>
              <th @click="sortByColumn('name')">
                <span>
                  Nombre
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('surname')">
                <span>
                  Apellidos
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('nif')">
                <span>
                  DNI
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('email')">
                <span>
                  Email
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('phone')">
                <span>
                  Teléfono
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th>A1/A3 Teórico</th>
              <th>A2 Teórico</th>
              <th>STS Teórico</th>
              <th>STS Práctico</th>
              <th>Radiofonista</th>
              <th>Otro</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pilot in pilotList" :key="pilot.pilotID" class="tr1">
              <td>{{ pilot.name }}</td>
              <td>{{ pilot.surname }}</td>
              <td>{{ pilot.nif }}</td>
              <td>{{ pilot.email }}</td>
              <td>{{ pilot.phone }}</td>
              <td><i v-if="pilot.A1A3T === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - A1/A3 Teórico', pilot.nif + 'A1A3T.pdf')"></i><p v-else>-</p></td>
              <td><i v-if="pilot.A2T === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - A2 Teórico', pilot.nif + 'A2T.pdf')"></i><p v-else>-</p></td>
              <td><i v-if="pilot.STST === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - STS Teórico', pilot.nif + 'STST.pdf')"></i><p v-else>-</p></td>
              <td><i v-if="pilot.STSP === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - STS Práctico', pilot.nif + 'STSP.pdf')"></i><p v-else>-</p></td>
              <td><i v-if="pilot.RAD === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - Radiononista ', pilot.nif + 'RAD.pdf')"></i><p v-else>-</p></td>
              <td><i v-if="pilot.Otros === 1" class="mdi mdi-file " @click="openPDF(pilot.name +  ' ' + pilot.surname + ' - Otros ', pilot.nif + 'Otros.pdf')"></i><p v-else>-</p></td>
              <td @click="removePilot(pilot.pilotID)">
                <i class="mdi mdi-trash-can"></i>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-row>
      <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>
        <v-row justify="end">
          <button class="btn" @click="openDialog">Añadir nuevo piloto</button>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Añadir nuevo UAS
            <v-btn class="close-icon" icon @click="dialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          <v-card-text>
            <v-row>
              <v-col><v-text-field v-model="pilot.name" label="Nombre"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.surname" label="Apellidos"  required></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="pilot.nif" label="NIF"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.phone" label="Teléfono"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.email" label="Email"  required></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="pilot.street" label="Calle"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.number" label="Número"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.cp" label="Código Postal"  required></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="pilot.city" label="Ciudad"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.state" label="Estado"  required></v-text-field></v-col>
            </v-row>
            
            <v-row>
              <v-col>
                <v-file-input
                  ref="A1A3T"
                  :label="pilot.A1A3T ? 'Archivo seleccionado' : 'Subir archivo A1/A3T'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-file-input
                  ref="A2T"
                  :label="pilot.A2T ? 'Archivo seleccionado' : 'Subir archivo A2T'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-file-input
                  ref="STSP"
                  :label="pilot.STSP ? 'Archivo seleccionado' : 'Subir archivo STSP'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-file-input
                  ref="STST"
                  :label="pilot.STST ? 'Archivo seleccionado' : 'Subir archivo STST'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-file-input
                  ref="RAD"
                  :label="pilot.RAD ? 'Archivo seleccionado' : 'Subir archivo RAD'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-file-input
                  ref="Otros"
                  :label="pilot.Otros ? 'Archivo seleccionado' : 'Subir archivo Otros'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
            </v-row>
            
            
            
            
          </v-card-text>
          <v-card-actions>
            <v-btn @click="createPilot" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="pdfVisible" max-width="80%">
        <v-card>
          <!-- Título centrado -->
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            {{ pdf.title }}
            <v-btn class="close-icon" icon @click="pdfVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Menú de navegación de páginas -->
          <v-card-actions>
            <v-btn @click="previousPagePDF" :disabled="this.pdf.page <= 1">Anterior</v-btn>
            <span>{{ this.pdf.page }} / {{ this.pdf.pageCount }}</span>
            <v-btn @click="nextPagePDF" :disabled="this.pdf.page >= this.pdf.pageCount">Siguiente</v-btn>
          </v-card-actions>
    
          <!-- Visor de PDF utilizando vue-pdf-embed -->
          <vue-pdf-embed
            ref="pdfRef"
            :source="this.pdf.path"
            :page=this.pdf.page
            @rendered="handleDocumentRender"
            
          />

          <v-card-actions>
            <v-btn @click="previousPagePDF" :disabled="this.pdf.page <= 1">Anterior</v-btn>
            <span>{{ this.pdf.page }} / {{ this.pdf.pageCount }}</span>
            <v-btn @click="nextPagePDF" :disabled="this.pdf.page >= this.pdf.pageCount">Siguiente</v-btn>
          </v-card-actions>
    
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import pilotsService from '@/services/pilotsService';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  name: 'PilotPage',
  computed: {
    ...mapState('auth', ['token', 'operatorID']),
    totalPages() {
      return Math.ceil(this.pilotList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedPilotList() {
      return this.pilotList.slice(this.startIndex, this.endIndex);
    },
  },    
  components: {
      VuePdfEmbed,
    },
  data() {
    return {
      pilotList: [],
      dialogVisible: false,
      pdfVisible: false,
      pilot: {
        name: '',
        surname: '',
        street: '',
        number: '',
        city: '',
        state: '',
        cp: '',
        phone: ' ',
        email: ' ',
        nif: ''
      },
      errorMessage: '',
      pdf:{
        path: "/files/example.pdf",
        title: "Titulo PDF",
        page: 1,
        pageCount: 1,
        isLoading: true,
      },
      refName: ["A1A3T", "A2T", "STST", "STSP", "RAD", "Otros"],
      sortColumn: '',
      sortOrder: 1, 
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
    };
  },
  mounted() {
    this.fetchPilots();
  },
  methods: {
    ...mapActions('auth', ['logOut']),

    handleDocumentRender() {
      this.pdf.isLoading = false;
      this.pdf.pageCount = this.$refs.pdfRef.pageCount;
    },
    previousPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
      }
    },
    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
      }
    },
      previousPagePDF() {
        if (this.pdf.page > 1) {
          this.pdf.page--;
        }
      },
      nextPagePDF() {
        if (this.pdf.page < this.pdf.pageCount) {
          this.pdf.page++;
        }
      },

    async fetchPilots() {
      try {
        this.pilotList = await pilotsService.getPilots(this.token, this.operatorID);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error("Error al obtener la lista de pilotos: ", error);
        }
      }
    },

    handleOutsideClick(){
      console.log("outside");
      this.dialogPDF = false;
    },

    renameFile(originalFile, newName) {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    },

    async createPilot() {
      try {

        const filesArray = [];
        const fileStatesArray = [];

        for (const refName of this.refName) {
          const fileInput = this.$refs[refName];

          // Verifica si el elemento tiene archivos seleccionados
          if (fileInput.files.length > 0) {
            filesArray.push(this.renameFile(fileInput.files[0], refName+".pdf"));
            fileStatesArray.push(1);
          } else {
            fileStatesArray.push(0);
          }
        }


        const response = await pilotsService.create(this.token, this.operatorID, this.pilot, filesArray, fileStatesArray);

        if (response == 201) {
          this.dialogVisible = false;
          this.fetchPilots();
        }
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error("Error al añadir nuevo piloto");
          this.errorMessage = "Error:" + error;
        }
      }
    },

    async removePilot(pilotID) {
      try {
        await pilotsService.remove(this.token, pilotID);
        this.fetchPilots();
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error('Error al eliminar el piloto:', error);
        }
      }
    },


    openDialog() {
      this.dialogVisible = true;
    },
    async openPDF(title, path) {
      this.pdf.title = title;
      console.log(path)
      //this.pdf.path = await filesService.get(this.token, this.operatorID, path);
      console.log(this.pdf.path.slice(5)+".pdf")
      this.pdfVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
      this.pilot.name = '';
      this.pilot.surname = '';
      this.pilot.street = '';
      this.pilot.number = '';
      this.pilot.city = '';
      this.pilot.state = '';
      this.pilot.cp = '';
      this.pilot.phone = ' ';
      this.pilot.email = ' ';
      this.pilot.nif = '';
      this.errorMessage = '';
    },

    sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        // Cambia la dirección de ordenamiento si se hace clic en la misma columna
        this.sortOrder *= -1;
      } else {
        // Establece la columna actualmente ordenada y restablece la dirección a ascendente
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }


      // Lógica para ordenar los datos aquí
      this.pilotList.sort((a, b) => {

        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        // Itera a través de las partes de la columna para acceder a la columna anidada
        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    }
  },
}
</script>

<style scoped>
  .close-icon{
    margin-top:-10px;
    margin-right:-10px;
  }

</style>
