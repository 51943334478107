<template>
  <div class="page no-mp">
    <div class="container no-mp">
      <div class="map-box">
        <div class="map-container" ref="mapContainer"></div>
      </div>
    </div>
  </div>
  


</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState, mapActions } from 'vuex';
import mapService from '@/services/mapService'; // Ruta al archivo mapService.js




export default {
  name: 'MapsPage',
  data(){
    return{
      map:null,
      coords:'',
      myZone:null,
    };
  },
  computed: {
    ...mapState('auth', ['token', 'userID', 'operatorID']) // Mapea el token del store
  },
  async mounted(){
    await this.fetchZones();
    await this.initializeMap();
    
  },
  methods: {
    ...mapActions('auth',["logOut"]),

    closeDialog(){
      this.dialogVisible = false;
    },

    createFlight(){
      this.$router.push({ name: 'newFlight', params: { 'coords': this.coords} });
    },

    async fetchZones(){
      try{
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {
          console.log
          if (zone.zoneID == this.operatorID){
            this.myZone = zone;
            console.log(this.myZone);
          }
        });
      }catch(error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener zona:', error);
        }
      }
      

       
    },
    async initializeMap(){

      this.map = L.map(this.$refs.mapContainer).setView([this.myZone.lat, this.myZone.lng], 10);
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();
      console.log(this.myZone);
      this.map.setView([this.myZone.lat, this.myZone.lng],12)

      this.map.on('click', (event) =>{
        this.coords = event.latlng
        this.dialogVisible = true;
      })
      
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);


        zones.forEach(zone => {
          if (zone.zoneID == this.userID){
            this.myZone = zone;
          }

          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    }

  }
}
</script>

<style scoped>
.map-container{
  width: 100%;
  height: calc(100vh - 64px);
}

.card-map{
  max-width:500px;
  margin: 0 auto;
  padding: 10px 20px;
}

.no-mp{
  margin:0;
  padding:0;
}
</style>
