// pilotService.js

import axios from 'axios';

import { API_BASE_URL } from './config.js';


// --------------------------------- PILOT INFO ---------------------------------

async function getAll(token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/pilots`, {
      headers: {
        Authorization: token
      }
    });

    return response.data[0];
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
}

async function get(token, pilotID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/pilots/${pilotID}`, {
      headers: {
        Authorization: token
      }
    });

    return response.data[0][0];
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
}

// --------------------------------- PILOT FLIGHTS ---------------------------------

async function getFlights(token, pilotID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/pilots/${pilotID}/flights`, {
      headers: {
        Authorization: token
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
}

async function getFutureFlights(token, pilotID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/pilots/${pilotID}/flights/future`, {
      headers: {
        Authorization: token
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
}

// --------------------------------- PILOT OPERATORS ---------------------------------

async function getOperators(token, pilotID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/pilots/${pilotID}/operators`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de operadores del piloto:', error);
    throw error;
  }
}

// --------------------------------- PILOT FILES ---------------------------------

async function getFile(token, pilotID, fileID) {
  try {
      const response = await axios.get(`${API_BASE_URL}/pilots/${pilotID}/files/${fileID}`, {
      headers: {
          Authorization: token,
      },
      responseType: 'blob',
      });
      

      // Crear un objeto URL para el blob y abrirlo en una nueva ventana
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Open the URL in a new window
      window.open(url, '_blank');


      return url;

  } catch (error) {
      console.error('Error al obtener el archivo:', error);
      throw error;
  }
}

async function getFileList(token, pilotID) {
  try {
      const response = await axios.get(`${API_BASE_URL}/pilot/${pilotID}/files/`, {
          headers: {
              Authorization: token
            },
          });
          
          return response.data[0];

  } catch (error) {
      console.error('Error al obtener la lista de archivos:', error);
      throw error;
  }
}

async function uploadFile(token, pilotID, files, description, selectFile) {

  try{
    const formData = new FormData();

    formData.append('description', description);
    formData.append('title', selectFile);
    
    for(var x = 0; x<files.length; x++) {
      formData.append('files', files[x]);
    }

    const response = await axios.post(`${API_BASE_URL}/pilots/${pilotID}/upload`, formData, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      },
    });

    return response.status;
  } catch (error) {
    console.error('Error al añadir el piloto:', error);
    throw error;
  }
}

async function removeFile(token, pilotID, fileID) {
  try {
      await axios.delete(`${API_BASE_URL}/pilots/${pilotID}/files/${fileID}`, {
          headers: {
              Authorization: token
            },
          });

  } catch (error) {
      console.error('Error al obtener la lista de archivos:', error);
      throw error;
  }
}

export default {
  getAll,
  get,
  //update

  getFlights,
  getFutureFlights,
  
  getOperators,

  getFile,
  getFileList,
  uploadFile,
  removeFile,
};
