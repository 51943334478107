<template>
  <div class="page">
    <div class="container" style="margin-top: 35px;">
      <v-container>
        <v-row>
          <h1>Vuelos realizados mi zona</h1>
        </v-row>
        <v-row class="table-container">
          <v-table class="table-100">
            <thead>
              <tr>
                <th @click="sortByColumn('start')">
                  <span>
                    Fecha Inicio
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'start' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'start' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('end')">
                  <span>
                    Fecha Fin
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'end' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'end' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('pilot.name')">
                  <span>
                    Piloto
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'pilot.name' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'pilot.name' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('pilot.name')">
                  <span>
                    Piloto
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'pilot.name' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'pilot.name' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('lat')">
                  <span>
                    Lugar
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'lat' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'lat' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('uas.sn')">
                  <span>
                    UAS
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'uas.sn' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'uas.sn' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('category')">
                  <span>
                    Categoría
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'category' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'category' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('observers')">
                  <span>
                    Observadores
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'observers' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'observers' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('urban')">
                  <span>
                    Zona Urbana
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'urban' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'urban' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('fpv')">
                  <span>
                    FPV
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'fpv' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'fpv' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('status')">
                  <span>
                    Estado
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'status' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'status' && sortOrder === -1 }"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="flight in flightList" :key="flight.flightID" class="tr1">
                <td @click="navigateToFlightDetails(flight.flightID)">{{ formatDateTime(flight.start) }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ formatDateTime(flight.end) }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flight.operatorID }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flight.pilot.name + " "+ flight.pilot.surname }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flight.lat + " / " + flight.lng }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flight.uas.model.manufacture + " " + flight.uas.model.model}}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flight.category }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ formatIcon(flight.observers) }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ formatIcon(flight.urbana) }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ formatIcon(flight.fpv) }}</td>
                <td @click="navigateToFlightDetails(flight.flightID)">{{ flightstatus(flight.status) }}</td>

              </tr>
            </tbody>
          </v-table>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>

      </v-container>
    

    
  </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import zoneService from '@/services/zoneService';
import flightService  from '@/services/flightService';



export default {
  name: 'FlightsPage',
  computed: {
    ...mapState('auth', ['token', 'operatorID']),

    totalPages() {
      return Math.ceil(this.flightList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedUASList() {
      return this.flightList.slice(this.startIndex, this.endIndex);
    },
  }, 
  data() {
    return {
      flightList: [],

      pagination: {
        rowsPerPage: 10,
        page: 1
      },

      sortColumn: '',
      sortOrder: 1, 

    };
  },
  mounted(){
    this.fetchFlights();
  },
  methods: {
    ...mapActions('auth',["logOut"]),
    async fetchFlights() {
      try {

        this.flightList = await zoneService.getFlights(this.token, this.operatorID);
        console.log(this.flightList);

      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
        console.error('Error al obtener datos de vuelos:', error);
        }
      }
    },


    formatIcon(value) {
      return value === 1 ? "✔" : "✘";
    },

    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },



    async deleteFlight(flightID){
      try{
        await flightService.remove(this.token, this.userID, flightID);
        this.fetchFlights();
      }catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        }
        else{
          console.error('Error al eliminar el vuelo:', error);
        }
      }
    },

    navigateToFlightDetails(flightID) {
      this.$router.push(`/admin/flight/${flightID}`);
    },

    flightstatus(status){
      switch (status) {
        case 0:
          return "pre-vuelo sin restricciones";
        case 1:
          return "pre-vuelo autorizado";
        case 2:
          return "no autorizado";
        case 3:
          return "pendiente de autorización";
        case 4:
          return "en vuelo";
        case 5:
          return "finalizado";
        default:
          return "Estado desconocido";
      }
    },

    async sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        this.sortOrder *= -1;
      } else {
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }

      this.flightList.sort((a, b) => {
        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },


  }
}
</script>

<style scoped>

</style>
