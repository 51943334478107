<template>
  <div class="page">
    <v-container>
      <v-row>
        <v-col class="ma-r-30">
          <div class="container">
            <v-row>
              <v-col><h1>Registrarse</h1></v-col>
            </v-row>
            <div class="separator-line3"></div>
            <div v-if="!prepass()">
              <v-text-field v-model="this.ppass" label="Contraseña de registro"  type="password" class="column"></v-text-field>
            </div>
            <div v-else>
              <v-row>
                <h2 class="ma-reg">Datos Acceso</h2>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="this.email" label="Email" type="email" placeholder="example@email.com" class="column" required></v-text-field></v-col>
                <v-col><v-text-field v-model="this.password" label="Contraseña" type="password"  class="column" required></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-select v-model="this.selectedRole" :items="roles" label="Rol" required></v-select></v-col>
              </v-row>
              <v-row v-if="this.isOperator()">
                <h2 class="ma-reg">Datos Operador</h2>
              </v-row>      
              <v-row v-if="this.isOperator()">
                <v-col><v-text-field v-model="this.operator.name" label="Nombre Operador (o representante)"   class="column"></v-text-field></v-col>
                <v-col><v-text-field v-model="this.operator.noperator" label="Numero Operador"   class="column"></v-text-field></v-col>
                <v-col><v-text-field v-model="this.operator.phone" label="Teléfono"   class="column"></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isOperator()">
                <v-col><v-text-field v-model="this.operator.address" label="Dirección"   class="column"></v-text-field></v-col>
                <v-col><v-text-field v-model="this.operator.cp" label="CP"   class="column"></v-text-field></v-col>
                <v-col><v-text-field v-model="this.operator.city" label="Ciudad"   class="column"></v-text-field></v-col>          
                <v-col><v-text-field v-model="this.operator.state" label="Provincia"   class="column"></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isOperator()">
                <v-col><v-button class="btn" @click="register">Registrarse</v-button></v-col>
                <v-col><p class="lr-link">¿Ya tienes una cuenta? <router-link to="/login" class="lr-a-link">Iniciar Sesión</router-link></p></v-col>
              </v-row>
              <v-row v-if="this.isPilot()">
                <h2 class="ma-reg">Datos Personales</h2>
              </v-row>      
              <v-row v-if="this.isPilot()">
                <v-col><v-text-field v-model="pilot.name" label="Nombre"  required></v-text-field></v-col>
                <v-col><v-text-field v-model="pilot.surname" label="Apellidos"  required></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isPilot()">
                <v-col><v-text-field v-model="pilot.nif" label="NIF"  required></v-text-field></v-col>
                <v-col><v-text-field v-model="pilot.phone" label="Teléfono"  required></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isPilot()">
                <v-col><v-text-field v-model="pilot.street" label="Calle"  required></v-text-field></v-col>
                <v-col><v-text-field v-model="pilot.number" label="Número"  required></v-text-field></v-col>
                <v-col><v-text-field v-model="pilot.cp" label="Código Postal"  required></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isPilot()">
                <v-col><v-text-field v-model="pilot.city" label="Ciudad"  required></v-text-field></v-col>
                <v-col><v-text-field v-model="pilot.state" label="Estado"  required></v-text-field></v-col>
              </v-row>
              <v-row v-if="this.isPilot()">
                <v-col><v-button class="btn" @click="register">Registrarse</v-button></v-col>
                <v-col><p class="lr-link">¿Ya tienes una cuenta? <router-link to="/login" class="lr-a-link">Iniciar Sesión</router-link></p></v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import auth from "@/services/auth";

export default {
  data() {
    return {
      email:'',
      password:'',
      selectedRole: '',
      userRole:'',
      ppass:"",
      operator:{
        name:'',
        noperator:'',
        phone:'',
        address:'',
        cp:'',
        city:'',
        state:'',
      },
      pilot: {
        name: '',
        surname: '',
        street: '',
        number: '',
        city: '',
        state: '',
        cp: '',
        phone: ' ',
        nif: ''
      },
      roles:[
        'Piloto',
        'Operador'
      ],
      errorMessage: '',
    };
  },
  methods: {

    async register() {
      try {
        console.log("submit");
        await auth.register(this.email, this.password, this.userRole, this.operator, this.pilot);
        this.$router.push("/login");
        this.showError = false
      } catch (error) {
        this.errorMessage = 'Error al registrar nuevo usuario';
        console.error('Error al registrar usuario:', error);
      }
    },

    prepass(){
      if (this.ppass =="rogodocs"){
        return true;
      }
      else{
        return false;
      }
    },


    isPilot(){
      if (this.selectedRole=="Piloto"){
        this.userRole = "pilot";
        return true
      }
      else{
        return false
      }
    },

    isOperator(){
      if (this.selectedRole=="Operador"){
        this.userRole = "operator";
        return true
      }
      else{
        return false
      }
    }
    
  },
};
</script>

<style scoped>
  .ma-reg{
    margin-left:20px;
  }

  .separator-line3 {
  border-top: 1px solid #ccc; /* Define el estilo de la línea (color, grosor, etc.) */
  margin-bottom: 30px;
  margin-top: 10px;
}
  
</style>
