<template>
  <v-app-bar app >
    
    <v-app-bar-title to="/" icon>
      Docs4Drones {{ this.userrole() }}
    </v-app-bar-title>
    <v-spacer></v-spacer>

    <v-btn v-if="this.isAdmin()" text to="/admin/map">
      Mapa
    </v-btn>
    <v-btn v-if="this.isAdmin()" text to="/admin/flights">
      Vuelos 
    </v-btn>
    <v-btn v-if="this.isAdmin()" text to="/admin/pilots">
      Pilotos 
    </v-btn>
    <v-btn v-if="this.isAdmin()" text to="/admin/operators">
      Operadoras 
    </v-btn>
    <v-btn v-if="this.isAdmin()" text to="/admin/zones">
      Zonas 
    </v-btn>

    

    <v-btn v-if="this.isZone()" text to="/zone/dashboard">
      Inicio
    </v-btn>
    <v-btn v-if="this.isZone()" text to="/zone/map">
      Mapa
    </v-btn>
    <v-btn v-if="this.isZone()" text to="/zone/flights">
      Vuelos 
    </v-btn>
    <v-btn v-if="this.isZone()" text to="/zone/pilots">
      Pilotos 
    </v-btn>
    <v-btn v-if="this.isZone()" text to="/zone/operators">
      Operadoras 
    </v-btn>

    <v-btn v-if="this.isOperator()" text to="/operator/dashboard">
      Inicio
    </v-btn>
    <v-btn v-if="this.isOperator()" text to="/operator/map">
      Mapa
    </v-btn>
    <v-btn v-if="this.isOperator()"  text to="/operator/uas">
      UAS
    </v-btn>
    <v-btn v-if="this.isOperator()"  text to="/operator/flights">
      Vuelos
    </v-btn>
    <v-btn v-if="this.isOperator()"  text to="/operator/pilots">
      Pilotos
    </v-btn>
    <v-btn v-if="this.isOperator()"  text to="/operator/maintenance">
      Mantenimiento
    </v-btn>
    <v-btn v-if="this.isOperator()"  text to="/operator/documents">
      Mis documentos
    </v-btn>

    <v-btn v-if="this.isPilot()" text to="/pilot/dashboard">
      Inicio
    </v-btn>
    <v-btn v-if="this.isPilot()" text to="/pilot/map">
      Mapa
    </v-btn>
    <v-btn v-if="this.isPilot()"  text to="/pilot/flights">
      Vuelos
    </v-btn>
    <v-btn v-if="this.isPilot()"  text to="/pilot/operators">
      Operadoras
    </v-btn>

    



    <!-- Mostrar icono de login o logout según el estado de autenticación -->
    <v-btn text v-if="!this.isLoggedIn" to="/login">
      <v-icon color="white" class="mr-4">mdi-login</v-icon>
      Iniciar Sesión
    </v-btn>
    <v-menu v-else>
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props">mdi-dots-vertical</v-icon>
      </template>
      <v-list>
        <v-list-item>
          <router-link to="/profile">
            <v-list-item-title>
              <v-icon class="mr-4">mdi-account</v-icon>
              Mi perfil
            </v-list-item-title>
          </router-link>
          <v-list-item-title @click ="logout">
            <v-icon class="mr-4">mdi-logout</v-icon>
            Cerrar Sesion
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MenuPage',
  data() {
    return {
      //
    };
  },

  methods: {
    ...mapActions('auth',["logOut"]),
    async logout() {
        this.logOut();
        this.$router.push("/"); 
    },

    isAdmin(){
      if(this.isLoggedIn && this.role=="admin"){
        return true
      }
      else{
        return false
      }
    },
    isZone(){
      if(this.isLoggedIn && this.role=="zone"){
        return true
      }
      else{
        return false
      }

    },
    isOperator(){
      if(this.isLoggedIn && this.role=="operator"){
        return true
      }
      else{
        return false
      }
    },
    isPilot(){
      if(this.isLoggedIn && this.role=="pilot"){
        return true
      }
      else{
        return false
      }
    },

    userrole(){
      if(this.isLoggedIn){
        return " - " + this.role
      }
      else{
        return ""
      }
    }
 

  },

  computed : {
      role : function(){ return this.$store.getters['auth/getRole']},
      isLoggedIn : function(){ return this.$store.getters['auth/isAuthenticated']}
  },


}
</script>
<style scoped>

.v-btn{
  margin-left:10px;
}


</style>
