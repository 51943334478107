// mapService.js

import axios from 'axios';

import { API_BASE_URL } from './config.js';


export default {
  async getZones(token) {
    try {
      const response = await axios.get(`${API_BASE_URL}/zones`, {
        headers: {
          Authorization: token
        }
      });
      const zones = response.data
      return zones;
    } catch (error) {
      console.error('Error al obtener las zonas:', error);
      throw error;
    }
  }
};
