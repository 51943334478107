import axios from 'axios';

import { API_BASE_URL } from './config.js';

async function getAll(token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/uas`, {
      headers: {
        Authorization: token
      }
    });

    const uas = response.data[0];

    return uas;
  } catch (error) {
    console.error('Error al obtener datos de UAS:', error);
    throw error;
  }
}

async function get(token, uasID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/uas/${uasID}`, {
      headers: {
        Authorization: token
      }
    });

    const uas = response.data[0][0];

    return uas;
  } catch (error) {
    console.error('Error al obtener datos de UAS:', error);
    throw error;
  }
}


async function remove(token, uasID) {
  try {
    await axios.delete(`${API_BASE_URL}/uas/${uasID}`, {
      headers: {
        Authorization: token
      }
    });
  } catch (error) {
    console.error('Error al eliminar UAS:', error);
    throw error;
  }
}

async function getAllModels(token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/models`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;

  } catch (error) {
    console.error('Error al obtener la lista de Modelos:', error);
    throw error;
  }
}

async function createModel(token, model) {
  try {

    const response = await axios.post(
      `${API_BASE_URL}/uas/model`,
      {
        model: model
      },
      {
        headers: {
          Authorization: token
        }
      }
    );

    return response.status;
  } catch (error) {
    console.error('Error al añadir el modelo:', error);
    throw error;
  }
}

async function getModel(token, modelID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/models/${modelID}`, {
      headers: {
        Authorization: token
      }
    });

    return response.data[0];
  } catch (error) {
    console.error('Error al obtener datos de modelo:', error);
    throw error;
  }
}

async function getAllManufactures(token){
  try {
    const response = await axios.get(`${API_BASE_URL}/manufactures`,{
      headers: {
        Authorization: token
      },
    });
    return response.data;
    } catch (error) {
      console.error('Error al obtener fabricantes:', error);
    }
}

async function getModelsManufacture(token, manufacture) {
  try {
    const response = await axios.get(`${API_BASE_URL}/manufactures/${manufacture}/models`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;

  } catch (error) {
    console.error('Error al obtener la lista de Modelos:', error);
    throw error;
  }
}











export default {
  getAll,
  get,
  remove,
  getAllModels,
  createModel,
  getModel,
  getAllManufactures,
  getModelsManufacture,
};