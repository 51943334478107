// auth.js

import axios from 'axios';

import { API_BASE_URL } from './config.js';


function checkAuthentication() {
  const token = localStorage.getItem('token');
  return !!token;
}

async function login(email, password, remember) {
  try {


    const response = await axios.post(`${API_BASE_URL}/login`, {
      email: email,
      password: password,
      remember: remember,
    });

    const token = response.data.token;
    const userID = response.data.userID;
    const role = response.data.role;
    const roleID = response.data.roleID;

    //console.log (response.data)


    return { token, userID, role, roleID };
  } catch (error) {

    throw new Error('Credenciales inválidas');
  }
}

async function register(email, password, role, operator, pilot) {

  try {

    await axios.post(`${API_BASE_URL}/register`, {
      email: email,
      password: password,
      role: role,
      operator: operator,
      pilot: pilot,

    });

    
  } catch (error) {
    throw new Error('Error al registrar nuevo usuario');
  }
}

async function regPilot(email, password, operator) {

  try {

    await axios.post(`${API_BASE_URL}/register`, {
      email: email,
      password: password,
      operator: operator

    });

    
  } catch (error) {
    throw new Error('Error al registrar nuevo usuario');
  }
}

function logout() {
  // console.log("Service Logout");

}

export default {
  isAuthenticated: checkAuthentication,
  login,
  register,
  regPilot,
  logout,
};
