<template>
  <div class="page">
    <v-container v-if="this.initialized">
      <v-row>
      <v-col cols="5" >
        <div class="container">
          <v-row style="margin-left: 5px; margin-bottom: 0px;"><h2>Vuelo: {{ this.flightID }}</h2></v-row>
          <v-row style="width:100%; margin-top: 0px;" class="d-flex justify-center align-center">
            <v-table density="compact" style="width:100%">
              <tbody>
                <tr>
                  <td>Inicio</td>
                  <td>{{ formatDate(this.flight.start) }}</td>
                  <td>{{ formatTime(this.flight.start) }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Fin</td>
                  <td>{{ formatDate(this.flight.end) }}</td>
                  <td>{{ formatTime(this.flight.end) }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Altitud</td>
                  <td>{{ this.flight.altitude }}</td>
                  <td>Radio</td>
                  <td>{{ this.flight.radius }}</td>
                </tr>
                <tr>
                  <td>Piloto</td>
                  <td>{{ this.flight.pilot.name + " " + this.flight.pilot.surname }}</td>
                  <td>DNI</td>
                  <td>{{ this.flight.pilot.nif}}</td>
                </tr>
                <tr>
                  <td>Teléfono</td>
                  <td>{{ this.flight.pilot.phone }}</td>
                  <td>Email</td>
                  <td>{{ this.flight.pilot.email}}</td>
                </tr>
                <tr>
                  <td >Observadores</td>
                  <td colspan="2">{{ formatIcon(this.flight.observers) }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <!--
              <v-alert v-if="this.flightState()==1" type="success" density="compact" class="alert">{{ flight.states[flight.states.length-1]?.state}}</v-alert>
            <v-alert v-if="this.flightState()==2" type="warning" density="compact" class="alert">{{ flight.states[flight.states.length-1]?.state}}</v-alert>
            <v-alert v-if="this.flightState()==0" type="error" density="compact" class="alert">{{ flight.states[flight.states.length-1]?.state}}</v-alert>
             -->                 
            <button class="btn bgreen" v-if="!this.checkFlight()" @click="startFlight()">Iniciar Vuelo</button>
            <button class="btn bred" v-else @click="endFlight()">Finalizar Vuelo</button>
            <button class="btn"  @click="openDialog()">Contactar</button>
          </v-row>
          
        </div>
      </v-col>

      <v-col>
        <div class="container" style="padding:10px">
          <v-row style="padding:0px">
            <div class="map-box">
              <div class="map-container" ref="mapContainer"></div>
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="container">
          <v-row style="padding-left:10px"><h2>UAS</h2></v-row>
          <v-row class="table-container">
            <v-table denstiy:compact  style="width:100%">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Fabricante</th>
                  <th>Modelo</th>
                  <th>SN</th>
                  <th>MTOM (kg)</th>
                  <th>Dim max (m)</th>
                  <th>Vel max. (m/s)</th>
                  <th>Configuración</th>
                  <th>Energía (J)</th>
                  <th>Camara</th>
                  <th>FPV</th>
                  <th>Clase</th>
                </tr>
              </thead>
              <tbody>
                <tr> 
                  <td>{{ this.flight.uas.uasID }}</td>
                  <td>{{ this.flight.uas.model.manufacture }}</td>
                  <td>{{ this.flight.uas.model.model }}</td>
                  <td>{{ this.flight.uas.sn }}</td>
                  <td>{{ formatDecimals(this.flight.uas.model.mtom) }}</td>
                  <td>{{ formatDecimals(this.flight.uas.model.wingspan) }}</td>
                  <td>{{ this.flight.uas.model.speed }}</td>
                  <td>{{ this.flight.uas.model.configuration }}</td>
                  <td>{{ this.flight.uas.model.energy }}</td>
                  <td>{{ formatIcon(this.flight.uas.model.camera) }}</td>
                  <td>{{ formatIcon(this.flight.uas.model.fpv) }}</td>
                  <td>{{ this.flight.uas.model.class }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="container">
          <v-row style="padding-left:10px"><h2>Estado</h2></v-row>
          
          <v-row class="table-container">
          <v-table density="compact" style="width:100%">
            <thead>
              <tr>
                <th>Estado</th>
                <th>Fecha</th>
                <th cols="5">Comentario</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(state, index) in this.flight.states" :key="index">
                <td>{{ state.state }}</td>
                <td>{{ formatDate(state.date)}} - {{ formatTime(state.date) }}</td>
                <td cols="5">{{ state.comment }}</td>
              </tr>
            </tbody>
          </v-table>

        </v-row>
        </div>
      </v-col>
      <v-col>
        <div class="container">
          <v-row><h2>Coordinaciones</h2></v-row>
          
          <v-row class="table-container">
            <v-table density="compact" style="width:100%">
            <thead>
              <tr>
                <th>Zona</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(conflict, index) in this.flight.conflicts" :key="index">
                <td>{{ conflict.zone.name }}</td>
                <td><v-alert type="warning" v-if="this.autorizationState(index) == 0" density="compact">{{ conflict.state }}</v-alert>
                  <v-alert type="success"  v-if="this.autorizationState(index) == 1" density="compact">{{ conflict.state }}</v-alert>
                  <v-alert type="error" v-if="this.autorizationState(index) == 2" density="compact">{{ conflict.state }}</v-alert>
                </td>
              </tr>
            </tbody>
          </v-table>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>

    <v-dialog v-model="contactVisible" style="width:500px"> 
      <v-card class="dialog-card">
        <v-card-title class="text-center pdf-title d-flex justify-space-between">
          Contactos
          <v-btn class="close-icon" icon @click="contactVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <v-card-text>
          <v-table>
            <tbody>
              <tr><td>Guardia Civil</td> <td>062</td></tr>
              <tr><td>Policia Local</td> <td>091</td></tr>
              <tr><td>Policia Nacional</td> <td>092</td></tr>
              <tr><td>Emergencias</td> <td>112</td></tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import flightService from '@/services/flightService';
import mapService from '@/services/mapService'; 

import { mapState, mapActions } from 'vuex';

export default {
  name: 'InfoFlightPage',
  computed: {
    ...mapState('auth', ['token', 'userID'])
  },
  data() {
    return {
      flight: [],
      flightID:"",
      initialized: false,
      contactVisible: false,
    }
  },
  async mounted(){
    this.flightID = this.$route.params.flightID
    await this.fetchFlight();
    this.initializeMap();

  },
  methods: {

    ...mapActions('auth',["logOut"]),
    
    checkFlight(){
      if(this.flight.states[this.flight.states.length - 1].state == "En vuelo"){
        return true;
      }
      else{
        return false;
      }
    },

    async startFlight(){

      await flightService.createState(this.token, this.flight.flightID, "En vuelo");
      
      await this.fetchFlight();
      

    },

    async endFlight(){

      await flightService.createState(this.token, this.flight.flightID, "Vuelo Finalizado");

      await this.fetchFlight();

    },

    autorizationState(index){
      if(this.flight.conflicts[index].state == "Pendiente de autorización"){
        return 0;
      }
      if(this.flight.conflicts[index].state == "Autorizado"){
        return 1;
      }
      if(this.flight.conflicts[index].state == "Denegado"){
        return 2;
      }
    },

    async initializeMap(){
      
      this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView([this.flight.lat, this.flight.lng], 14);
      this.map.invalidateSize();

      this.drawOperation()

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();

      
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {

          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },

    async fetchFlight() {
      try {
        this.flight = await flightService.get(this.token, this.flightID);
        this.initialized = true;
        console.log(this.flight);

      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
        console.error('Error al obtener datos de vuelos:', error);
        }
      }
    },

    drawOperation(){
      this.map.setView([this.flight.lat, this.flight.lng], 14);

      if (this.circle) {
          this.map.removeLayer(this.circle);
        }

        this.circle = L.circle([this.flight.lat, this.flight.lng], {
            color: 'blue',
            fillOpacity: 0.2,
            radius: this.flight.radius,
          }).addTo(this.map);
    },

    flightState(){
      if(this.flight.states[this.flight.states.length-1]?.state == "Listo para volar"){
        return 1;
      }
      if (this.flight.states[this.flight.states.length-1]?.state == "Pendiente de autorización") {
        return 2;
      } else {
        return 0;
      }

    },

    formatDecimals(value) {
      const number = Number(value);
      if (!isNaN(number)) {
        return number.toFixed(3); 
      }
      return '';
    },

    formatIcon(value) {
      return value === 1 ? "✔" : "✘";
    },

    formatDate(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}/${month}/${year}`;
    },

    formatTime(dateTime) {
      const date = new Date(dateTime);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

    openDialog() {
      this.contactVisible = true;
    },

  
  },
}

</script>

<style scoped>
.bred{
  background-color: red !important;
}

.bgreen{
  background-color: green !important;
}
</style>
