<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Mis Documentos</h1>
        </v-row>
        <v-row class="table-container">

          <v-table  class="table-100">
            <thead>
              <tr>
                <th @click="sortByColumn('Titulo')">
                  <span>
                    Titulo
                    <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('Descripcion')">
                  <span>
                    Descripcion
                    <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('Model')">
                  <span>
                    Fecha subida
                    <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th>
                    Descargar
                </th>
                <th>
                    Eliminar
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, index) in fileList" :key="index">
                <td>{{ file.title }}</td>
                <td>{{ file.description }}</td>
                <td>{{ formatDateTime(file.lastmodification) }}</td>
                <td>
                  <i class="mdi mdi-file-download" @click="getFile(index)"></i>
                </td>
                <td>
                  <i class="mdi mdi-trash-can" @click="removeFile(file.fileID)"></i>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>
        <v-row justify="end">
          <button class="btn" @click="openDialog">Añadir nuevo documento</button>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Añadir nuevo archivo
            <v-btn class="close-icon" icon @click="dialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-row>
              <v-col><v-select v-model="this.selectFile" :items="this.typeFiles" label="Tipo de archivo" required></v-select></v-col>
              <v-col>
                <v-file-input
                  ref="pdffile"
                  :label="file ? 'Archivo seleccionado' : 'Subir archivo'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="this.description" label="Descripción"  placeholder="Certificado práctico categoría abierta , subcategorias A1/A3" class="column" required></v-text-field></v-col>
            </v-row>
            <v-row v-if="this.selectFile == 'Seguro'">
              <v-col> 
                  ¿El seguro es para todos los UAS de la operadora o para un UAS en concreto?

                  <v-radio-group v-model="this.allUas">

                      <v-radio label="Sí" value="true"></v-radio>
                      <v-radio label="No" value="false"></v-radio>

                  </v-radio-group>
              </v-col> 
            </v-row>
            <v-row v-if="this.allUas=='false'">
              <v-col>
                Seleccionar UAS para el que es el seguro
                <v-select v-model="this.uasID" :items="myUAS" label="UAS" item-title="Label" item-value="Id" required class="column"></v-select>
              </v-col>  
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="uploadFile" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import operatorService from '@/services/operatorService';

export default {
  name: 'PilotPage',
  computed: {
    ...mapState('auth', ['token', 'roleID']),
    totalPages() {
      return Math.ceil(this.fileList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedfileList() {
      return this.fileList.slice(this.startIndex, this.endIndex);
    },
  },
  data() {
    return {
      fileList: [],
      
      selectFile: "Certificado Operador",
      typeFiles:["Certificado Operador", "Manual Operadora", "Seguro", "EARO", "Declaración Operacional"],
      description:"",
      file: null,
      dFile: null,
      allUas: "true",
      myUAS: [],
      uasID: null,

      dialogVisible: false,

      errorMessage: '',
      sortColumn: '',
      sortOrder: 1, 
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
    };
  },
  async mounted() {
    await this.fetchFiles();
    await this.fetchUAS();
  },
  methods: {
    ...mapActions('auth', ['logOut']),

    /* 
    ------- Documentos -------
    fetchFiles()
    getFile()
    renameFile()
    uploadFile()    

    ------- UAS -------
    fetchUAS()

    ------- Fecha y Hora -------
    getCurrentDateTime()
    formatDateTime(dateString)

    ------- Dialogos -------
    openDialog()
    closeDialog()

    ------- Orden y Navegación Tabla -------
    sortByColumn()
    nextPage()
    previousPage()

    */




 // --------------------------------- Documentos ---------------------------------   

    async fetchFiles() {
      try {
        this.fileList = await operatorService.getFileList(this.token, this.roleID);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error("Error al obtener la lista de documentos: ", error);
        }
      }
    },

    async getFile(index){
      try{
        
        this.dFile = await operatorService.getFile(this.token, this.roleID, this.fileList[index].fileID);
        
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
          console.error('Error al obtener archivo:', error);
        }
      }
    },

    renameFile(originalFile, newName) {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    },

    async uploadFile() {
      try {

        const filesArray = [];
        const fileInput = this.$refs['pdffile'];

        
        if(fileInput.files.length>0){
          filesArray.push(this.renameFile(fileInput.files[0], this.selectFile+this.getCurrentDateTime()+".pdf"));

          if (this.allUas == "true" || this.selectFile != "Seguro"){this.uasID=0;}

          const response = await operatorService.uploadFile(this.token, this.roleID, filesArray, this.description, this.selectFile, this.uasID);
        
          if (response == 201) {
            this.closeDialog();
            this.fetchFiles();
          }
        }
        
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error("Error al añadir nuevo documento");
          console.error(error);
          this.errorMessage = "Error:" + error;
        }
      }
    },

    async removeFile(fileID){
      console.log("remove")
      console.log(fileID)
      try {
        await operatorService.removeFile(this.token, this.roleID, fileID);
        this.fetchFiles();
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error('Error al eliminar documento:', error);
        }
      }
    },


  // --------------------------------- UAS --------------------------------- 
  async fetchUAS() {
      try {

        const uasList = await operatorService.getUas(this.token, this.roleID);
        const uasL = [];

        for (const uas of uasList) {
          const label = uas.model.manufacture + " " + uas.model.model + " (" + uas.sn + ")";
          uasL.push({Id: uas.uasID, Label: label});

        }

        this.myUAS = uasL;
      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
        console.error('Error al obtener datos de UAS:', error);
        }
      }
    },

 // -------------------------------- Fecha y Hora -------------------------------- 
    getCurrentDateTime(){
      const now = new Date();
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const year = now.getFullYear().toString().substr(-2);
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${day}${month}${year}${hours}${minutes}${seconds}`;
    },
 
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },

 // ---------------------------------- Dialogos ----------------------------------   
 
    openDialog() {
      this.dialogVisible = true;
    },

    closeDialog() {
      this.uasID = null,
      this.selectFile = "Certificado Operador";
      this.description = "";
      this.dialogVisible = false;
      this.allUas = "true";

    },

 // -------------------------- Orden y Navegación Tabla --------------------------   
 
    sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        // Cambia la dirección de ordenamiento si se hace clic en la misma columna
        this.sortOrder *= -1;
      } else {
        // Establece la columna actualmente ordenada y restablece la dirección a ascendente
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }


      // Lógica para ordenar los datos aquí
      this.fileList.sort((a, b) => {

        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        // Itera a través de las partes de la columna para acceder a la columna anidada
        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },

    previousPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
      }
    },

    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
      }
    },


    



  },


}
</script>

<style scoped>
  .close-icon{
    margin-top:-10px;
    margin-right:-10px;
  }

</style>
