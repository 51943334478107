// flightService.js

import axios from 'axios';

import { API_BASE_URL } from './config.js';


async function getAll(token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/flights`, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}

async function get(token, flightID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/flights/${flightID}`, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener vuelos:', error);
    throw error;
  }
}


async function remove(token, flightID){
  try {
    await axios.delete(`${API_BASE_URL}/flights/${flightID}`, {
      headers: {
        Authorization: token
      }
    });
  } catch (error) {
    console.error('Error al eliminar vuelo:', error);
    throw error;
  }
}

async function createState(token, flightID, state) { 

  try {

    const response = await axios.post(`${API_BASE_URL}/flights/${flightID}/state/`, {
          state: state,
      }, {
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al añadir estado:', error);
    throw error;
  }
  
}

async function createConflict(token, flightID, conflict) { 

  try {

    const response = await axios.post(`${API_BASE_URL}/flights/${flightID}/conflict/`, {
          conflict: conflict,
      }, {
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al añadir conflicto:', error);
    throw error;
  }
  
}


export default {
    getAll,
    get,
    //update,
    remove,
    createState,
    createConflict,
  };
  