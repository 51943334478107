
import auth from '@/services/old2/auth';

const state = {
    isLogin: false,
    token: null,
    userID: null,
    role: null,
    roleID: null,

};

const getters = {
  isAuthenticated: (state) => state.isLogin,
  getToken: (state) => state.token,
  getUserID: (state) => state.userID,
  getRoleID: (state) => state.roleID,
  getRole: (state) => state.role,
};

const mutations = {
  loginSuccess(state, {token, userID, role, roleID}){
    state.isLogin = true;
    state.token = token;
    state.userID = userID;
    state.roleID = roleID;
    state.role = role;
  },
  resetState (state){
    state.isLogin = false;
    state.token = null;
    state.userID = null;
    state.role = null;
    state.roleID = null;
  },
  logFailure (state){
    state.isLogin =false;
    state.token = null;
    state.userID = null;
    state.role = null;
    state.roleID = null;
  }

};

const actions = {
  
  async  logInSession({commit}, {email, password, remember}){
    const { token, userID, role, roleID } = await auth.login(email, password, remember);
    
    commit('loginSuccess', {token, userID, role, roleID});
  },

  logOut({commit}){
    // console.log("Action Logout");
    commit('resetState');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
