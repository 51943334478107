<template>
  <div class="page">
    <v-container>
      <v-row>
        <v-col>
          <div class="container">
              <v-row>
                <h2>Panel de control</h2>
              </v-row>
              <v-row>
                <v-col>   
                  <v-table denstiy:compact  style="width:100%">
                    <tbody>
                      <tr> 
                        <td><b>Nombre</b></td>
                        <td>{{this.pilot.name}}</td>
                      </tr>
                      <tr> 
                        <td><b>Apellidos</b></td>
                        <td>{{this.pilot.surname}}</td>
                      </tr>
                      <tr> 
                        <td><b>NIF</b></td>
                        <td>{{this.pilot.nif}}</td>
                      </tr>
                      <tr> 
                        <td><b>Teléfono</b></td>
                        <td>{{this.pilot.phone}}</td>
                      </tr>
                      <tr> 
                        <td><b>Email</b></td>
                        <td>{{this.pilot.email}}</td>
                      </tr>
                      <tr> 
                        <td><b>Dirección</b></td>
                        <td>{{this.pilot.street}} {{this.pilot.number}}, {{this.pilot.cp}}, {{this.pilot.city}}, {{this.pilot.state}}</td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-col>
                <v-col cols="8">
                  <v-table denstiy:compact  style="width:100%">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Descripción</th>
                      <th>Fecha de subida</th>
                      <th>Descarga</th>
                      <th>Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) in fileList" :key="index">
                      <td>{{ file.title }}</td>
                      <td>{{ file.description }}</td>
                      <td>{{ formatDateTime(file.lastmodification) }}</td>
                      <td>
                        <i class="mdi mdi-file-download" @click="getFile(index)"></i>
                      </td>
                      <td>
                        <i class="mdi mdi-trash-can" @click="deleteFile(index)"></i>
                      </td>
                    </tr>
                  </tbody>
                </v-table>

                </v-col>
              </v-row>                
                  <v-row justify="end">
                    <button class="btn" @click="openDialog">Añadir nuevo archivo</button>
                  </v-row>
          </div>
        </v-col>
      </v-row>

      
      <v-row>
        <v-col class="ma-r-30"><div class="container">
            <v-row>
              <v-col  class="ma-tn-30">
                <i class="mdi mdi-earth dashboard-icon"></i>
              </v-col>
              <v-col>
                <h3 class="text-right">Vuelos Hoy</h3>
                <h2 class="text-right">{{this.dataFlight.today}}</h2>
              </v-col>
            </v-row>
            <div class="line-1"></div>
          </div></v-col>
          
          <v-col><div class="container">
            <v-row>
              <v-col  class="ma-tn-30">
                <i class="mdi mdi-calendar-clock dashboard-icon"></i>
              </v-col>
              <v-col>
                <h3 class="text-right">Vuelos Total</h3>
                <h2 class="text-right">{{this.dataFlight.tomorrow}}</h2>
              </v-col>
            </v-row>
            <div class="line-1"></div>
          </div></v-col>
        </v-row> 
        <v-row>
          <v-col>
          <div class="container">
              <v-row><h2>Proximo vuelo programado</h2></v-row>
              <v-row>
                <v-col>
                  
                  <div class="container-2">
                        <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <v-table density="compact" class="v-table-trans" @click="navigateToFlightDetails(futureFlights[currentIndex].flightID)">
                          <tbody>
                            <tr>
                              <th>Día:</th>
                              <td>{{ formatDate(futureFlights[currentIndex].start)}}</td>
                            </tr>
                            <tr>
                              <th>Hora</th>
                              <td>{{formatTime(futureFlights[currentIndex].start)   }}</td>
                            </tr>
                            <tr>
                              <th>Operador</th>
                              <td>{{ futureFlights[currentIndex].operator.name + " " + futureFlights[currentIndex].operator.noperator }}</td>
                            </tr>
                            <tr>
                              <th>UAS</th>
                              <td>{{ futureFlights[currentIndex].uas.model.manufacture + " " + futureFlights[currentIndex].uas.model.model + " (" + futureFlights[currentIndex].uas.sn + ") " }}</td>
                            </tr>
                            <tr>
                              <th>Estado</th>
                              <td><v-alert v-if="this.flightState()==1" type="info" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==2" type="warning" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==0" type="error" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==3" type="success" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert></td>
                            
                            </tr>
                          </tbody>
                        </v-table>
                      </v-row>
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <button class="btn bgreen" v-if="!this.checkFlight()" @click="startFlight()">Iniciar Vuelo</button>
                        <button class="btn bred" v-else @click="endFlight()">Finalizar Vuelo</button>
                        <button class="btn">Contactar</button>
                      </v-row>
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <button @click="previousFlight"><i class="mdi mdi-chevron-left"></i></button>
                        {{ currentIndex+1 + "/" + futureFlights.length }}
                        <button @click="nextFlight"><i class="mdi mdi-chevron-right"></i></button>
                      </v-row>
                      <v-row v-else>
                        <h2>No hay vuelos programados</h2>
                      </v-row>
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="map-box">
                    <div class="map-container" ref="mapContainer"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Añadir nuevo archivo
            <v-btn class="close-icon" icon @click="dialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-row>
              <v-col><v-select v-model="this.selectFile" :items="this.typeFiles" label="Tipo de archivo" required></v-select></v-col>
              <v-col>
                <v-file-input
                  ref="pdffile"
                  :label="file ? 'Archivo seleccionado' : 'Subir archivo'"
                  show-size
                  accept=".pdf" 
                  :max-size="5 * 1024 * 1024"
                ></v-file-input>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="this.description" label="Descripción"  placeholder="Certificado práctico categoría abierta , subcategorias A1/A3" class="column" required></v-text-field></v-col>
            </v-row>
            
            
            
            
          </v-card-text>
          <v-card-actions>
            <v-btn @click="uploadFile" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState, mapActions} from 'vuex';
import mapService from '@/services/mapService'; 
import pilotsService from '@/services/pilotsService'; 
import flightService from '@/services/flightService';



export default {
  name: 'DashboardPage',  
  computed: {
    ...mapState('auth', ['token', 'role', 'roleID'])
  }, 
  data(){
    return{
      dataFlight:{today:0, active:0, tomorrow:0},
      dialogVisible: false,
      pilot: [],
      isNext: false,
      futureFlights: [],
      currentIndex: 0,
      description:"",
      file: null,
      dFile: null,
      fileList: [],
      selectFile: "A1A3 Teorico",
      typeFiles:["A1A3 Teorico", "A2 Teorico", "A2 Autopractico", "STS Teorico", "STS-01 Practico", "STS-02 Practico", "Radiofonista", "Otro" ]
    }
  },
  async mounted(){

    await this.fetchPilot();
    await this.fetchFiles();
    await this.initializeMap();
    await this.getFutureFlights();

    

  },
  methods:{

    ...mapActions('auth',["logOut"]),
    async drawOperation(index){
      this.map.setView([this.futureFlights[index].lat, this.futureFlights[index].lng],15);

      if (this.circle) {
        this.map.removeLayer(this.circle);
      }

      this.circle = L.circle([this.futureFlights[index].lat, this.futureFlights[index].lng], {
        color: 'blue',
        fillOpacity: 0.2,
        radius: this.futureFlights[0].radius,
      }).addTo(this.map);
    },
    
    async fetchPilot(){
      try{
        this.pilot = await pilotsService.get(this.token, this.roleID);

      }
      catch(error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener zona:', error);
        }
      }
      

       
    },

    flightState(){
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "Listo para volar"){
        return 1;
      }
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "Pendiente de autorización") {
        return 2;
      }
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "En vuelo"){
        return 3;
      } 
      else {
        return 0;
      }

    },

    checkFlight(){
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length - 1].state == "En vuelo"){
        return true;
      }
      else{
        return false;
      }
    },

    async startFlight(){

      await flightService.createState(this.token, this.futureFlights[this.currentIndex].flightID, "En vuelo");
      
      
    await this.getFutureFlights();

    },

    async endFlight(){

      await flightService.createState(this.token, this.futureFlights[this.currentIndex].flightID, "Vuelo Finalizado");

      await this.getFutureFlights();
    },

    async fetchFiles(){
      try{
        this.fileList = await pilotsService.getFileList(this.token, this.roleID);
      }
      catch(error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener lista de archivos:', error);
        }
      }
      

       
    },

    navigateToFlightDetails(flightID) {
      this.$router.push(`/pilot/flight/${flightID}`);
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {
         
          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },

    async getFutureFlights(){
      try{
        
        this.futureFlights = await pilotsService.getFutureFlights(this.token, this.roleID);
        console.log(this.futureFlights);
        if (this.futureFlights.length>0){
          this.drawOperation(0);
        }
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener si existe futuro vuelo:', error);
        }
      }
    },


    async getFile(index){
      try{
        
        this.dFile = await pilotsService.getFile(this.token, this.fileList[index].fileID);
        
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener archivo:', error);
        }
      }
    },

    async deleteFile(index){
      try{
        
        await pilotsService.removeFile(this.token, this.fileList[index].fileID);
        this.fetchFiles();
        
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al eliminar archivo:', error);
        }
      }
    },

    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },
  

    async initializeMap(){

      const asturiasCoords = [43.3, -5.8593];
      this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView(asturiasCoords, 9);
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();
    },

    previousFlight() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.drawOperation(this.currentIndex);
      }
    },

    nextFlight() {
      if (this.currentIndex < this.futureFlights.length - 1) {
        this.currentIndex++;
        this.drawOperation(this.currentIndex);
      }
    },

    openDialog() {
      this.dialogVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
    },



    renameFile(originalFile, newName) {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    },

    async uploadFile() {
      try {

        const filesArray = [];
        const fileInput = this.$refs['pdffile'];

      
        if(fileInput.files.length>0){
          filesArray.push(this.renameFile(fileInput.files[0], this.selectFile+this.getCurrentDateTime()+".pdf"));

          const response = await pilotsService.uploadFile(this.token, this.roleID, filesArray, this.description, this.selectFile);
        
          if (response == 201) {
            this.dialogVisible = false;
            this.fetchFiles();
          }
        }
        
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error("Error al añadir nuevo piloto");
          console.error(error);
          this.errorMessage = "Error:" + error;
        }
      }
    },

    getCurrentDateTime(){
      const now = new Date();
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const year = now.getFullYear().toString().substr(-2);
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${day}${month}${year}${hours}${minutes}${seconds}`;
    },

    formatDate(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}/${month}/${year}`;
    },

    formatTime(dateTime) {
      const date = new Date(dateTime);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

  }
    
}

</script>

<style scoped>
.dashboard-icon{
  font-size: 80px;
  color:#2E838C;
  }

  .admin{
    margin-top: -10px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .bred{
  background-color: red !important;
}

.bgreen{
  background-color: green !important;
}
</style>

