<template>
  <div class="page login-background">
    <div class="container login-container" style="height: auto;">
      <v-container>
        <v-row class="mp-0">
          <v-col><h1>Iniciar Sesión</h1></v-col>
        </v-row>
        <div class="separator-line3"></div>
        <v-row class="mp-0">
          <v-col class="mp-0"><v-text-field v-model="this.email" label="Email" type="email" placeholder="example@email.com"></v-text-field></v-col>
        </v-row>
        <v-row class="mp-0">
          <v-col class="mp-0"><v-text-field v-model="this.password" label="Contraseña" type="password" ></v-text-field></v-col>
        </v-row>
        <v-row class="mp-0">
          <v-col class="mp-0">
            <v-checkbox v-model="remember" label="Recordarme"></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mp-0">
          <v-col class="mp-0"><v-btn class="btn" @click="login">Iniciar Sesión</v-btn></v-col>
          <v-col class="mp-0"><p class="link">¿Aún no tienes cuenta? <router-link to="/register" class="a-link">Registrarse</router-link></p></v-col>
        </v-row >

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </v-container>
    </div>
  </div>
</template>




<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      remember: false,
    };
  },  
  computed: {
    ...mapState('auth', ['role'])
  },
  methods: {
    ...mapActions('auth', ["logInSession"]),
    async login() {
      try{

        await this.logInSession({email: this.email, password: this.password, remember: this.remember});

      
        if (this.role === "admin") {
          this.$router.push("/admin"); // Redirige a un panel de administrador
        } else if(this.role == "zone"){
          this.$router.push("/zone/dashboard"); // Redirige a un panel de usuario básico
        } else if(this.role == "operator"){
          this.$router.push("/operator/dashboard"); // Redirige a un panel de usuario básico
        }else{
          this.$router.push("/pilot/dashboard"); // Redirige a un panel de usuario básico
        }
      } catch (error){
        this.errorMessage = 'Credenciales inválidas';
      console.error('Error al iniciar sesión:', error);
      }
    },
 

  },
};
</script>

<style scoped>

.login-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
  background-image: url('@/assets/images/drone_background.jpg'); /* Agrega 'url()' y la ruta de la imagen */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}


 .login-container {
    min-width: 20%;
    max-width: 500px
  }




</style>
