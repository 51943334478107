<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Mantenimiento</h1>
        </v-row>
      <v-row class="table-container">
        <v-table  class="table-100">
          <thead>
            <tr>
              <th @click="sortByColumn('UasID')">
                <span>
                  UAS
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('Manufacture')">
                <span>
                  Fabricante
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('Model')">
                <span>
                  Modelo
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('Date')">
                <span>
                  Fecha
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('worker')">
                <span>
                  Técnico Responsable
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('Action')">
                <span>
                  Acción realizada
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="maintenance in MaintenanceList" :key="maintenance.maintenanceID" class="tr1">
              <td>{{ maintenance.uas.uasID}}</td>
              <td>{{ maintenance.model.manufacture }}</td>
              <td>{{ maintenance.model.model }}</td>
              <td>{{ this.formatDate(maintenance.date)}}</td>
              <td>{{ maintenance.worker }}</td>
              <td>{{ maintenance.action }}</td>
              <td @click="removeMaintenance(maintenance.maintenanceID)">
                <i class="mdi mdi-trash-can"></i>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-row>
      <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>
        <v-row justify="end">
          <button class="btn" @click="openDialog">Añadir nuevo mantenimiento</button>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Añadir nuevo Mantenimiento
            <v-btn class="close-icon" icon @click="dialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          <v-card-text>
            <v-row>
              <v-col><v-select v-model="maintenance.uasID" :items="myUAS" label="UAS" item-title="Label" item-value="Id" required class="column"></v-select></v-col>  
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="maintenance.worker" label="Técnico Responsable:"  required></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="maintenance.action" label="Acción realizada"  required></v-text-field></v-col>
            </v-row>
            
            
          </v-card-text>
          <v-card-actions>
            <v-btn @click="createMaintenance" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import operatorService from '@/services/operatorService';

export default {
  name: 'PilotPage',
  computed: {
    ...mapState('auth', ['token', 'roleID']),
    totalPages() {
      return Math.ceil(this.MaintenanceList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedMaintenanceList() {
      return this.MaintenanceList.slice(this.startIndex, this.endIndex);
    },
  },
  data() {
    return {
      MaintenanceList: [],
      dialogVisible: false,
      myUAS: [],
      maintenance: {
        uasID:'',
        worker:'',
        action:''
      },
      errorMessage: '',
      sortColumn: '',
      sortOrder: 1, 
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
    };
  },
  mounted() {
    this.fetchMaintenance();
    this.fetchUAS();
  },
  methods: {
    ...mapActions('auth', ['logOut']),

/* 
    ------- Mantenimiento -------
    fetchMaintenance()
    createMaintenance()
    removeMaintenance()

    ------- UAS -------
    fetchUAS()

    ------- Fecha y Hora -------
    formatDate(dateString)

    ------- Dialogos -------
    openDialog()
    closeDialog()
    handleOutsideClick()

    ------- Orden y Navegación Tabla -------
    sortByColumn()
    nextPage()
    previousPage()

    */

 // -------------------------------- Mantenimiento -------------------------------
 async fetchMaintenance() {
      try {
        this.MaintenanceList = await operatorService.getMaintenances(this.token, this.roleID);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error("Error al obtener la lista de mantenimientos: ", error);
        }
      }
    },

    async createMaintenance() {
      try {

        const response = await operatorService.createMaintenance(this.token, this.roleID, this.maintenance);

        if (response == 201) {
          this.dialogVisible = false;
          this.fetchMaintenance();
        }
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error("Error al añadir nuevo piloto");
          this.errorMessage = "Error:" + error;
        }
      }
    },

    async removeMaintenance(maintenanceID) {
      try {
        await operatorService.removeMaintenance(this.token, this.roleID, maintenanceID);
        this.fetchMaintenance();
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error('Error al eliminar el mantenimiento:', error);
        }
      }
    },

 // ------------------------------------- UAS ------------------------------------ 
    async fetchUAS() {
      try {

        const uasList = await operatorService.getUas(this.token, this.roleID);
        const uasL = [];

        for (const uas of uasList) {
          const label = uas.model.manufacture + " " + uas.model.model + " (" + uas.sn + ")";
          uasL.push({Id: uas.uasID, Label: label});

        }

        this.myUAS = uasL;
      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
        console.error('Error al obtener datos de UAS:', error);
        }
      }
    },

 // -------------------------------- Fecha y Hora -------------------------------- 
    formatDate(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}/${month}/${year}`;
    },

 // ---------------------------------- Dialogos ----------------------------------
    openDialog() {
      this.dialogVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
      this.maintenance.worker = '';
      this.maintenance.action = '';
    },

    handleOutsideClick(){
      console.log("outside");
      this.dialogPDF = false;
    },

 // -------------------------- Orden y Navegación Tabla -------------------------- 
    sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        // Cambia la dirección de ordenamiento si se hace clic en la misma columna
        this.sortOrder *= -1;
      } else {
        // Establece la columna actualmente ordenada y restablece la dirección a ascendente
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }


      // Lógica para ordenar los datos aquí
      this.MaintenanceList.sort((a, b) => {

        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        // Itera a través de las partes de la columna para acceder a la columna anidada
        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },

    previousPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
      }
    },

    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
      }
    },
    

  },
    
}
</script>

<style scoped>
  .close-icon{
    margin-top:-10px;
    margin-right:-10px;
  }

</style>
