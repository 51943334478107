<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Mis Pilotos</h1>
        </v-row>
      <v-row class="table-container">
        <v-table  class="table-100">
          <thead>
            <tr>
              <th @click="sortByColumn('name')">
                <span>
                  Nombre
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'name' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('surname')">
                <span>
                  Apellidos
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'surname' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('nif')">
                <span>
                  DNI
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'nif' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('email')">
                <span>
                  Email
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'email' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th @click="sortByColumn('phone')">
                <span>
                  Teléfono
                  <i class="mdi mdi-chevron-up " :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === 1 }"></i>
                  <i class="mdi mdi-chevron-down icon-ord" :class="{ 'purple-icon': sortColumn === 'phone' && sortOrder === -1 }"></i>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pilot in pilotList" :key="pilot.pilotID" class="tr1">
              <td>{{ pilot.pilot.name }}</td>
              <td>{{ pilot.pilot.surname }}</td>
              <td>{{ pilot.pilot.nif }}</td>
              <td>{{ pilot.pilot.email }}</td>
              <td>{{ pilot.pilot.phone }}</td>
              <td @click="removePilot(pilot.pilotID)">
                <i class="mdi mdi-trash-can"></i>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-row>
      <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>
        <v-row justify="end">
          <button class="btn" @click="openDialog">Añadir nuevo piloto</button>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Añadir nuevo Piloto
            <v-btn class="close-icon" icon @click="dialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          <v-card-text>
            <v-row>
              <v-col><v-text-field v-model="pilot.nif" label="NIF"  @update:modelValue="checkNIF" required></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col>{{ this.message }}</v-col>
            </v-row>
            <v-row v-if="cNif">
              <v-col><v-text-field v-model="pilot.name" label="Nombre"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.surname" label="Apellidos"  required></v-text-field></v-col>
            </v-row>
            <v-row v-if="cNif">
              <v-col><v-text-field v-model="pilot.phone" label="Teléfono"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.email" label="Email"  required></v-text-field></v-col>
            </v-row>
            <v-row v-if="cNif">
              <v-col><v-text-field v-model="pilot.street" label="Calle"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.number" label="Número"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.cp" label="Código Postal"  required></v-text-field></v-col>
            </v-row>
            <v-row v-if="cNif">
              <v-col><v-text-field v-model="pilot.city" label="Ciudad"  required></v-text-field></v-col>
              <v-col><v-text-field v-model="pilot.state" label="Estado"  required></v-text-field></v-col>
            </v-row>
            
            
          </v-card-text>
          <v-card-actions>
            <v-btn @click="createPilot" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>



    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import operatorService from '@/services/operatorService';

export default {
  name: 'PilotPage',
  computed: {
    ...mapState('auth', ['token', 'roleID']),
    totalPages() {
      return Math.ceil(this.pilotList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedPilotList() {
      return this.pilotList.slice(this.startIndex, this.endIndex);
    },
  },    
  data() {
    return {
      pilotList: [],
      dialogVisible: false,
      pdfVisible: false,
      cNif: false,
      message: '',
      pilot: {
        name: '',
        surname: '',
        street: '',
        number: '',
        city: '',
        state: '',
        cp: '',
        phone: ' ',
        email: ' ',
        nif: ''
      },
      errorMessage: '',

      
      sortColumn: '',
      sortOrder: 1, 
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
    };
  },
  mounted() {
    this.fetchPilots();
  },
  methods: {
    ...mapActions('auth', ['logOut']),

    /* 
    ------- Pilotos -------
    fetchPilota()
    createPilot()
    removePilot()
    searchPilotByNIF()

    ------- Dialogos -------
    openDialog()
    closeDialog()
    handleOutsideClick)=

    ------- Orden y Navegación Tabla -------
    sortByColumn()
    nextPage()
    previousPage()

    */

    // ----------------------------------- Pilotos ----------------------------------
    async fetchPilots() {
      try {
        this.pilotList = await operatorService.getPilots(this.token, this.roleID);
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        } else {
          console.error("Error al obtener la lista de pilotos: ", error);
        }
      }
    },
    async createPilot() {
      try {
        var response = null
        if(this.cNif){
          console.log("create");
          response = await operatorService.createPilot(this.token, this.roleID, this.pilot);
        }else{
          response = await operatorService.addPilot(this.token, this.roleID, this.pilot.pilotID);
        }


        if (response == 201) {
          this.closeDialog();
          this.fetchPilots();
        }
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error("Error al añadir nuevo piloto");
          this.errorMessage = "Error:" + error;
        }
      }
    },

    async removePilot(pilotID) {
      try {
        await operatorService.removePilot(this.token, this.roleID, pilotID);
        this.fetchPilots();
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        } else {
          console.error('Error al eliminar el piloto:', error);
        }
      }
    },

    async checkNIF(){
      if (/^\d{8}[a-zA-Z]$/.test(this.pilot.nif)) {
        try {
          this.message = "Consultando";
          const pilots = await operatorService.getPilotByNif(this.token, this.pilot.nif);
          if (pilots[0].length>0){
            this.cNif = false;
            this.message = "Usuario encontrado";
            this.pilot = pilots[0][0];
          }
          else{
            this.cNif = true;
            this.message = "Usuario no encontrado - puedes crearlo a continuación";
          }
        } catch (error) {
          if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
            console.log('Token Expirado - redireccion a pagina de acceso',);
            this.logOut();
            this.$router.push("/login");
          } else {
            console.error("Error al obtener el piloto: ", error);
          }
        }
      } 
      else {
          
          this.message = "Introduce el DNI en formado (00000000X)";
          this.cNif = false;
      }
      
    },

    // ---------------------------------- Dialogos ----------------------------------

    openDialog() {
      this.dialogVisible = true;
    },
    async openPDF(title, path) {
      this.pdf.title = title;
      console.log(path)
      //this.pdf.path = await filesService.get(this.token, this.operatorID, path);
      console.log(this.pdf.path.slice(5)+".pdf")
      this.pdfVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
      this.pilot.name = '';
      this.pilot.surname = '';
      this.pilot.street = '';
      this.pilot.number = '';
      this.pilot.city = '';
      this.pilot.state = '';
      this.pilot.cp = '';
      this.pilot.phone = ' ';
      this.pilot.email = ' ';
      this.pilot.nif = '';
      this.errorMessage = '';
    },

    handleOutsideClick(){
      console.log("outside");
      this.dialogPDF = false;
    },

    // -------------------------- Orden y Navegación Tabla --------------------------   
    sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        // Cambia la dirección de ordenamiento si se hace clic en la misma columna
        this.sortOrder *= -1;
      } else {
        // Establece la columna actualmente ordenada y restablece la dirección a ascendente
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }


      // Lógica para ordenar los datos aquí
      this.pilotList.sort((a, b) => {

        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        // Itera a través de las partes de la columna para acceder a la columna anidada
        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },
    previousPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
      }
    },
    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
      }
    },
    

    

    

    
  },
}
</script>

<style scoped>
  .close-icon{
    margin-top:-10px;
    margin-right:-10px;
  }

</style>
