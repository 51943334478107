// flightService.js

import axios from 'axios';

import { API_BASE_URL } from './config.js';


async function get(token, flightID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/flights/${flightID}`, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}

async function getFlights(token, zoneID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/zones/${zoneID}/flights`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}

async function getFuture(token, zoneID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/zones/${zoneID}/flights/future`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}







export default {
    get,
    getFlights,
    getFuture,
  };
  