<template>
  <div class="page">
    <v-container>
      <v-row>
        <v-col>
          <div class="container" style="padding-bottom:0px;">
              <h2>Panel de control - {{ this.myZone.name }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-r-30"><div class="container">
            <v-row>
              <v-col  class="ma-tn-30">
                <i class="mdi mdi-earth dashboard-icon"></i>
              </v-col>
              <v-col>
                <h3 class="text-right">Vuelos Hoy</h3>
                <h2 class="text-right">{{this.dataFlight.today}}</h2>
              </v-col>
            </v-row>
            <div class="line-1"></div>
          </div></v-col>
          <v-col class="ma-r-30"><div class="container">
            <v-row>
              <v-col  class="ma-tn-30">
                <i class="mdi mdi-quadcopter dashboard-icon"></i>
              </v-col>
              <v-col>
                <h3 class="text-right">Vuelos en Activo</h3>
                <h2 class="text-right">{{this.dataFlight.active}}</h2>
              </v-col>
            </v-row>
            <div class="line-1"></div>
          </div></v-col>
          <v-col><div class="container">
            <v-row>
              <v-col  class="ma-tn-30">
                <i class="mdi mdi-calendar-clock dashboard-icon"></i>
              </v-col>
              <v-col>
                <h3 class="text-right">Vuelos Mañana</h3>
                <h2 class="text-right">{{this.dataFlight.tomorrow}}</h2>
              </v-col>
            </v-row>
            <div class="line-1"></div>
          </div></v-col>
        </v-row> 
        <v-row>
          <v-col>
          <div class="container">
              <v-row><h2>Proximo vuelo</h2></v-row>
              <v-row>
                <v-col>
                  
                  <div class="container-2">
                        <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <v-table density="compact" class="v-table-trans" >
                          <tbody>
                            <tr>
                              <th>Día:</th>
                              <td>{{ formatDate(futureFlights[currentIndex].start)}}</td>
                            </tr>
                            <tr>
                              <th>Hora</th>
                              <td>{{formatTime(futureFlights[currentIndex].start)   }}</td>
                            </tr>
                            <tr>
                              <th>Piloto</th>
                              <td>{{ futureFlights[currentIndex].pilot.name + " " + futureFlights[currentIndex].pilot.surname }}</td>
                            </tr>
                            <tr>
                              <th>UAS</th>
                              <td>{{ futureFlights[currentIndex].uas.model.manufacture + " " + futureFlights[currentIndex].uas.model.model + " (" + futureFlights[currentIndex].uas.sn + ") " }}</td>
                            </tr>
                            <tr>
                              <th>Estado</th>
                              <td></td>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-row>
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <v-button class="btn">Contactar</v-button>
                      </v-row>
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <button @click="previousFlight"><i class="mdi mdi-chevron-left"></i></button>
                        {{ currentIndex+1 + "/" + futureFlights.length }}
                        <button @click="nextFlight"><i class="mdi mdi-chevron-right"></i></button>
                      </v-row>
                      <v-row v-else>
                        <h2>No hay vuelos programados</h2>
                      </v-row>
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="map-box">
                    <div class="map-container" ref="mapContainer"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState } from 'vuex';
import mapService from '@/services/mapService'; 
import zoneService from '@/services/zoneService'; 



export default {
  name: 'DashboardPage',  
  computed: {
    ...mapState('auth', ['token', 'role', 'roleID'])
  }, 
  data(){
    return{
      dataFlight:{today:0, active:0, tomorrow:0},

      myZone: [],
      isNext: false,
      futureFlights: [],
      currentIndex: 0,
    }
  },
  async mounted(){
    await this.fetchZones();
    await this.initializeMap();
    await this.getFutureFlights();
    await this.getInit();

    

  },
  methods:{

    async drawOperation(index){
      this.map.setView([this.futureFlights[index].lat, this.futureFlights[index].lng],15);

      if (this.circle) {
        this.map.removeLayer(this.circle);
      }

      this.circle = L.circle([this.futureFlights[index].lat, this.futureFlights[index].lng], {
        color: 'blue',
        fillOpacity: 0.2,
        radius: this.futureFlights[0].radius,
      }).addTo(this.map);
    },
    
    async fetchZones(){
      try{
        const zones = await mapService.getZones(this.token);
        zones.forEach(zone => {
          if (zone.zoneID == this.roleID){
            this.myZone = zone;
          }
        });
      }
      catch(error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener zona:', error);
        }
      }
      

       
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {
         
          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('es-ES', options);
    },

    formatTime(dateString) {
      const date = new Date(dateString);
      const options = { hour: '2-digit', minute: '2-digit' };
      return date.toLocaleTimeString('es-ES', options);
    },

    async getFutureFlights(){
      try{
        
        this.futureFlights = await zoneService.getFuture(this.token, this.operatorID);
        if (this.futureFlights.length>0){
          this.drawOperation(0);
        }
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al obtener si existe futuro vuelo:', error);
        }
      }
    },

    async getInit(){
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      today.setHours(0, 0, 0, 0);
      tomorrow.setHours(23, 59, 59, 999);

      const nfuture = this.futureFlights.length;

      for (let i = 0; i < nfuture; i++) {
        const flight = this.futureFlights[0];
        const start = new Date(flight.start);
        const end = new Date(flight.end);

        // Ignorar las horas al comparar
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        if (today >= start && today <= end) {
          this.dataFlight.today = this.dataFlight.today +1;
        }
        if (tomorrow >= start && tomorrow <= end) {
          this.dataFlight.tomorrow = this.dataFlight.tomorrow +1;
        }
        if(flight.state[flight.state.length]?.state == "En vuelo"){
          this.dataFlight.active = this.dataFlight.active +1;
        }
        
      }
    },

    async initializeMap(){

      this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView([this.myZone.lat, this.myZone.lng], 16);
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();
    },

    previousFlight() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.drawOperation(this.currentIndex);
      }
    },

    nextFlight() {
      if (this.currentIndex < this.futureFlights.length - 1) {
        this.currentIndex++;
        this.drawOperation(this.currentIndex);
      }
    },
  }
    
}

</script>

<style scoped>
.dashboard-icon{
  font-size: 80px;
  color:#2E838C;
  }

  .admin{
    margin-top: -10px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
</style>

