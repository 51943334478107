<template>
  <v-app>
    <MainMenu />
    <v-main>
      <div>
        <router-view></router-view>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import MainMenu from './components/MenuPage.vue'
import Footer from './components/FooterPage.vue'

export default {
  name: 'App',

  components: {
    MainMenu,
    Footer,
  },

  data: () => ({
    //
  }),
}
</script>


<style>

@import '@/assets/styles/main.css';


</style>
