import axios from 'axios';

import { API_BASE_URL } from './config.js';

// --------------------------------- OPERATOR INFO ---------------------------------

async function getAll(token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/`, {
      headers: {
        Authorization: token
      }
    });

    const operator = response.data[0];
  

    return operator;
  } catch (error) {
    console.error('Error al obtener datos de UAS:', error);
    throw error;
  }
}

async function get(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}`, {
      headers: {
        Authorization: token
      }
    });

    const operator = response.data[0][0];
  

    return operator;
  } catch (error) {
    console.error('Error al obtener datos de UAS:', error);
    throw error;
  }
}

// --------------------------------- OPERATOR UAS ---------------------------------

async function getUas(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/uas`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de UAS:', error);
    throw error;
  }
}

async function createUas(token, operatorID, uas) {
  try {
    if (!uas.modelID || !uas.sn) {
      throw new Error('Todos los campos obligatorios deben llenarse.');
    }

    const response = await axios.post(
      `${API_BASE_URL}/operators/${operatorID}/uas`,
      {
        uas: uas
      },
      {
        headers: {
          Authorization: token
        }
      }
    );

    return response.status;
  } catch (error) {
    console.error('Error al añadir el UAS:', error);
    throw error;
  }
}

async function removeUas(token, uasID) {
  try {

    const response = await axios.delete(`${API_BASE_URL}/uas/${uasID}`, {
      headers: {
          Authorization: token
      }
    });

    return response.status
  } catch (error) {
    console.error('Error al eliminar uas al operador:', error);
    throw error;
  }
    
}

// --------------------------------- OPERATOR FLIGHTS ---------------------------------

async function getFlights(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/flights`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}

async function createFlight(token, operatorID, newFlight, zoneConflict) {

  
  if (!newFlight.startDate){
    throw new Error('Falta fecha de inicio.');
  }else{
    newFlight.startDate = new Date(newFlight.startDate).toISOString().slice(0, 19).replace('T', ' ');
  }
  if (!newFlight.endDate) {
    throw new Error('Falta fecha de fin.');
  }
  else{
    newFlight.endDate = new Date(newFlight.endDate).toISOString().slice(0, 19).replace('T', ' ');
  }
  if (newFlight.pilot == "" ) {
    throw new Error('Falta seleccionar piloto.');
  }
  if (newFlight.uasID == ""){
    throw new Error('Falta seleccionar UAS.');
  }
  if (newFlight.category == "") {
    throw new Error('Falta seleccionar categoria.');
  }
  if (newFlight.radius == "") {
    throw new Error('Falta seleccionar radio de operación.');
  }
  if (newFlight.altitude == "") {
    throw new Error('Falta seleccionar altura máxima de vuelo durante operación.');
  }    
  if (newFlight.coords == ""){
    throw new Error('Falta seleccionar lugar.');
  }

  try {

    const response = await axios.post(`${API_BASE_URL}/operators/${operatorID}/flights/`, {
          flight: newFlight,
      }, {
          headers: {
              Authorization: token
          }
    });

    const {flightID} = response.data


    if(zoneConflict.length>0) {
      const state = "Pendiente de autorización";
      await axios.post(`${API_BASE_URL}/flights/${flightID}/state/`, {
        state: state
      },{
        headers: {
            Authorization: token
        }
      }); 

      for(const zone of zoneConflict){
        const state = "Pendiente de autorización";
        await axios.post(`${API_BASE_URL}/flights/${flightID}/conflict/`, {
          state: state,
          zone: zone
        },{
          headers: {
              Authorization: token
          }
        }); 
      }
    
    }
    else{
      const state = "Listo para volar";
      await axios.post(`${API_BASE_URL}/flights/${flightID}/state/`, {
        state: state
      },{
        headers: {
            Authorization: token
        }
      }); 
    }

    await axios.post(`${API_BASE_URL}/flights/${flightID}/mail/`, {
        subject: "New Flight"
    },{
      headers: {
        Authorization: token
      }
    });
    
    return response.status
  } catch (error) {
    console.error('Error al añadir el vuelo:', error);
    throw error;
  }
  
}

async function getFutureFlights(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/flights/future`, {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de vuelos:', error);
    throw error;
  }
}

// --------------------------------- OPERATOR PILOTS ---------------------------------

async function getPilots(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/pilots`, {
      headers: {
        Authorization: token
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
  
}

async function getPilotByNif(token, nif) {
  try {
    const response = await axios.post(`${API_BASE_URL}/pilot`, {
      nif:nif
    },
    {
      headers: {
        Authorization: token
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error consultar piloto:', error);
    throw error;
  }
  
}

async function createPilot(token, operatorID, pilot) {
  try {

    const response = await axios.post(`${API_BASE_URL}/operators/${operatorID}/pilot`, {
        pilot: pilot
      }, {
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al añadir piloto al operador:', error);
    throw error;
  }
    
}

async function addPilot(token, operatorID, pilotID) {

  try {

    const response = await axios.post(`${API_BASE_URL}/operators/${operatorID}/pilots/${pilotID}`, {
      }, {
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al añadir piloto al operador:', error);
    throw error;
  }
    
}

async function removePilot(token, operatorID, pilotID) {
  try {

    const response = await axios.delete(`${API_BASE_URL}/operators/${operatorID}/pilots/${pilotID}`,{
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al eliminar piloto al operador:', error);
    throw error;
  }
    
}

// --------------------------------- OPERATOR MAINTENANCE ---------------------------------

async function getMaintenances(token, operatorID) {
  try {
    const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/maintenance`, {
      headers: {
        Authorization: token
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de pilotos:', error);
    throw error;
  }
  
}

async function createMaintenance(token, operatorID, maintenance) {
  try {

    const response = await axios.post(`${API_BASE_URL}/operators/${operatorID}/maintenance`, {
      maintenance: maintenance,  
    }, {
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al añadir piloto al operador:', error);
    throw error;
  }
    
}

async function removeMaintenance(token, operatorID, maintenanceID) {
  try {

    const response = await axios.delete(`${API_BASE_URL}/operators/${operatorID}/maintenance/${maintenanceID}`,{
          headers: {
              Authorization: token
          }
    });

    return response.status
  } catch (error) {
    console.error('Error al eliminar piloto al operador:', error);
    throw error;
  }
    
}

// --------------------------------- OPERATOR FILES ---------------------------------

async function getFile(token, operatorID, fileID) {
  try {
      const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/files/${fileID}`, {
      headers: {
          Authorization: token,
      },
      responseType: 'blob',
      });
      

      // Crear un objeto URL para el blob y abrirlo en una nueva ventana
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Open the URL in a new window
      window.open(url, '_blank');


      return url;

  } catch (error) {
      console.error('Error al obtener el archivo:', error);
      throw error;
  }
}

async function getFileList(token, operatorID) {
  try {
      const response = await axios.get(`${API_BASE_URL}/operators/${operatorID}/files/`, {
          headers: {
              Authorization: token
            },
          });
          
          return response.data[0];

  } catch (error) {
      console.error('Error al obtener la lista de archivos:', error);
      throw error;
  }
}

async function uploadFile(token, operatorID, files, description, selectFile, uasID) {

  try{
    const formData = new FormData();

    formData.append('description', description);
    formData.append('title', selectFile);
    formData.append('uasID', uasID);
    
    for(var x = 0; x<files.length; x++) {
      formData.append('files', files[x]);
    }

    const response = await axios.post(`${API_BASE_URL}/operators/${operatorID}/upload`, formData, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      },
    });

    return response.status;
  } catch (error) {
    console.error('Error al añadir documento:', error);
    throw error;
  }
}

async function removeFile(token, operatorID, fileID) {
  try {
      await axios.delete(`${API_BASE_URL}/operators/${operatorID}/files/${fileID}/`, {
          headers: {
              Authorization: token
            },
          });

  } catch (error) {
      console.error('Error al obtener la lista de archivos:', error);
      throw error;
  }
}


export default {
  getAll,
  get,

  getUas,
  createUas,
  removeUas,
  
  getFlights,
  createFlight,
  getFutureFlights,
  
  getPilots,
  getPilotByNif,
  createPilot,
  addPilot,
  removePilot,
  
  getMaintenances,
  createMaintenance,
  removeMaintenance,

  getFile,
  getFileList,
  uploadFile,
  removeFile,
  
};
