<template>
  <div class="page no-mp">
    <div class="container no-mp">
      <div class="map-box">
        <div class="map-container" ref="mapContainer"></div>
      </div>



      <v-dialog v-model="dialogVisible">
        <v-card class="card-map">
          <v-container class="pa-40">
            <v-row>
              <v-col><h2>Añadir Vuelo</h2></v-col>
              <v-col class="text-right"><v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn></v-col>
            </v-row>
            <v-row>
              ¿Quieres añadir un vuelo en estas coordenadas?
              <span>{{ this.coords }}</span>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn @click="navigateToNewFlight" class="btn">Añadir</v-btn>
            <v-btn @click="closeDialog" class="btn btn-trans">Cancelar</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </div>
  </div>
  


</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState, mapActions } from 'vuex';
import mapService from '@/services/mapService'; // Ruta al archivo mapService.js




export default {
  name: 'MapsPage',
  data(){
    return{
      map:null,
      coords:'',
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState('auth', ['token']) // Mapea el token del store
  },
  async mounted(){
    await this.initializeMap();
    
  },
  methods: {
    ...mapActions('auth',["logOut"]),

    /* 
    ------- Mapa -------
    inizializeMap()
    drawZones()    

    ------- Vuelo -------
    navigateToNewFlight()


    ------- Dialogos -------
    openDialog()
    closeDialog()

    */

 // --------------------------------- MAPA  ---------------------------------
    async initializeMap(){
      const asturiasCoords = [43.3, -5.8593];

      this.map = L.map(this.$refs.mapContainer).setView(asturiasCoords, 10);
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();

      this.map.on('click', (event) =>{
        this.coords = event.latlng
        this.dialogVisible = true;
      })
      
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {

          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },
 // -------------------------------- Vuelos --------------------------------- 
    navigateToNewFlight(){
      this.$router.push({ name: 'newFlightCoords', params: { 'coords': this.coords} });
    },
    
 // ------------------------------- Dialogos -------------------------------- 
    openDialog(){
      this.dialogVisible = true;
    },
    closeDialog(){
      this.dialogVisible = false;
    },




  }
}
</script>

<style scoped>
.map-container{
  width: 100%;
  height: calc(100vh - 64px);
}

.card-map{
  max-width:500px;
  margin: 0 auto;
  padding: 10px 20px;
}

.no-mp{
  margin:0;
  padding:0;
}
</style>
