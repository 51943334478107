<template>
  <div class="page">
    <div class="container" style="margin-top:35px">
      <v-container>
        <v-row>
          <h1>Nuevo Vuelos</h1>
        </v-row>
        <v-row>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </v-row>
        <v-row>
          <v-col style="padding:25px">
            <v-row>Fecha Inicio</v-row>
            <v-row><VueDatePicker v-model="newFlight.startDate" locale="es" :min-date="new Date()" select-text="Escoger" cancel-text="Cancelar"></VueDatePicker></v-row>
          </v-col>
          <v-col style="padding:25px">
            <v-row>Fecha Fin</v-row>
            <v-row><VueDatePicker v-model="newFlight.endDate" locale="es" :min-date="newFlight.startDate" select-text="Escoger" cancel-text="Cancelar"></VueDatePicker></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col><v-select v-model="newFlight.pilot" :items="myPilots" label="Piloto" item-title="Label" item-value="Id" requiered class="column"></v-select></v-col>
          <v-col><v-select v-model="newFlight.uasID" :items="myUAS" label="UAS" item-title="Label" item-value="Id" required class="column"></v-select></v-col>  
          <v-col><v-select v-model="newFlight.category" :items="categories" label="Categoría" required class="column"></v-select></v-col>
        </v-row>
        <v-row>
          <v-col><v-text-field v-model="newFlight.radius" label="Radio de Operación" @update:modelValue="drawOperation"   @input="filterNumbers" required class="column"></v-text-field></v-col>
          <v-col><v-text-field v-model="newFlight.altitude" label="Altura de Operación" @input="filterNumbers" required class="column"></v-text-field></v-col>
        </v-row>
        <v-row style="margin-top:-30px;">
          <v-col><v-checkbox v-model="newFlight.fpv" label="Operación FPV"></v-checkbox></v-col>
          <v-col><v-checkbox v-model="newFlight.urban" label="Operación en Zona Urbana"></v-checkbox></v-col>
          <v-col><v-checkbox v-model="newFlight.observers" label="Se cuenta con observadores"></v-checkbox></v-col>
        </v-row>
        <v-row style="margin-top:-30px;">
          <div class="map-box">
            <div class="map-container" ref="mapContainer"></div>
          </div>
        </v-row>
        <v-row>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </v-row>
        <v-row>
          <v-btn @click="createFlight" class="btn">Añadir</v-btn>
          <v-btn @click="navigationToCancel" class="btn btn-trans">Cancelar</v-btn>
        </v-row>
      </v-container>
    
  </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState, mapActions } from 'vuex';
import mapService from '@/services/mapService'; 
import operatorService from '@/services/operatorService';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  name: 'FlightsPage',
  computed: {
    ...mapState('auth', ['token', 'roleID'])
  }, 
  components: { VueDatePicker },
  data() {
    return {
      map:null,
      myUAS: [],
      myPilots: [],
      categories: ["A1/A3", "A2", "STS-01", "STS-02", "Certificada"],
      newFlight:{
        startDate: null,
        endDate: null,
        pilot: "",
        uasID: "",
        coords: null,
        category: "",
        radius: "",
        altitude: "",
        fpv: false,
        urban: false,
        observers: false,
      },
      zones: [],
      zonesConflict: [],
      errorMessage: "",
      circle: null,
      marker: null,
    };
  },
  async mounted(){
    await this.fetchPilots();
    await this.fetchUAS();

    // Default radius
    this.newFlight.radius=100;

    // Check if there are predefine coordinates (from maps page)
    const coords = this.$route.params.coords;
      if(coords){
      let regex = /\(([^,]+),/; // Expresión regular para capturar el número entre '(' y ','
      const lat = regex.exec(coords)[1];
      regex = /, ([^)]+)\)/; // Expresión regular para capturar el número entre ', ' y ')'
      const lng = regex.exec(coords)[1];
      const coords2 = {lat: lat, lng: lng};
      this.newFlight.coords = coords2;
      
    }

    await this.initializeMap();

  },
  methods: {
    ...mapActions('auth',["logOut"]),
    
    /* 
    ------- Mapa -------
    inizializeMap()
    drawOperation()
    drawZones()    

    ------- Vuelos -------
    createFlight()

    ------- UAS -------
    fetchUAS()

    ------- Piloto -------
    fetchPilots()

    ------- Zona -------
    zoneConflictions()
    isPointInsideCircle()
    degreesToRadians()
    haversineDistance()

    ------- Navegación -------
    navigationToCancel()

    ------- Filtrado -------
    filterNumbers()


    */
 // --------------------------------- MAPA  --------------------------------- 
    async initializeMap(){
      if (this.newFlight.coords){
        this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView(this.newFlight.coords, 16);

      }else{
        const asturiasCoords = [43.4, -5.8593];
        this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView(asturiasCoords, 9);

      }
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();


      var myIcon = L.icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png',
        iconSize: [20, 35],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });

      if (this.newFlight.coords){
        this.drawOperation();
        this.marker = L.marker(this.newFlight.coords, { icon: myIcon }).addTo(this.map);
        this.zoneConflictions();
      }


      if(this.map){
        this.map.on('click', (event) => {
          if (this.marker) {
            this.map.removeLayer(this.marker); 
          }
          if (this.circle) {
            this.map.removeLayer(this.circle);
          }

          this.newFlight.coords = event.latlng;
          this.marker = L.marker(this.newFlight.coords, { icon: myIcon }).addTo(this.map);
          this.map.setView(this.newFlight.coords, 17);

          this.zoneConflictions();

          if (this.newFlight.radius){
            this.circle = L.circle(this.newFlight.coords, {
              color: 'blue',
              fillOpacity: 0.2,
              radius: this.newFlight.radius,
            }).addTo(this.map);
          }
        });
      }
    },

    drawOperation(){
      if (this.newFlight.coords){
        if (this.circle) {
          this.map.removeLayer(this.circle);
        }
        this.circle = L.circle(this.newFlight.coords, {
            color: 'blue',
            fillOpacity: 0.2,
            radius: this.newFlight.radius,
          }).addTo(this.map);
      }
    },

    async drawZones() {
      try {
        this.zones = await mapService.getZones(this.token);

        this.zones.forEach(zone => {

          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },
 
 // -------------------------------- Vuelos -------------------------------
 
    async createFlight(){
      try {
        const response = await operatorService.createFlight(this.token, this.roleID, this.newFlight, this.zonesConflict);

        if (response==201){

          this.$router.push("/operator/flights");
        }


      } catch (error) {
        
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
        this.errorMessage = error;
        console.error('Error al registrar vuelo:', error);
        }
      }
    },
  
 // --------------------------------- UAS --------------------------------- 

  async fetchUAS() {
      try {

        const uasList = await operatorService.getUas(this.token, this.roleID);
        console.log(uasList);
        const uasL = [];

        for (const uas of uasList) {
          const label = uas.model.manufacture + " " + uas.model.model + " (" + uas.sn + ")";
          uasL.push({Id: uas.uasID, Label: label});

        }

        this.myUAS = uasL;
      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
        console.error('Error al obtener datos de UAS:', error);
        }
      }
    },
 
 // ------------------------------- Pilotos ------------------------------- 

    async fetchPilots(){
      try{
        const pilotsList = await operatorService.getPilots(this.token, this.roleID);
        const pilotsL = [];

        for (const pilotL of pilotsList) {
          const pilot = pilotL.pilot;
          const label = pilot.name + " " + pilot.surname + " (" + pilot.nif + ")";
          pilotsL.push({ Id: pilot.pilotID, Label: label });

        }

        this.myPilots = pilotsL;

      }catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
          console.error("Error al obtener la lista de pilotos: ", error);
        }
      }
    },
    

    
 // ------------------------------- Zonas ------------------------------- 
    zoneConflictions(){
      this.zonesConflict = [];

      this.zones.forEach(zone => {
        if(this.isPointInsideCircle(this.newFlight.coords.lat, this.newFlight.coords.lng, zone.lat, zone.lng, zone.radius)){
          this.zonesConflict.push(zone);
        }
        
      });
    },

    isPointInsideCircle(pointLat, pointLon, circleLat, circleLon, circleRadius) {
      const distance = this.haversineDistance(pointLat, pointLon, circleLat, circleLon);
      return distance <= circleRadius;
    },

    degreesToRadians(degrees) {
      return degrees * (Math.PI / 180);
    },

    haversineDistance(lat1, lon1, lat2, lon2) {
      const earthRadius = 6371000; // Radio de la Tierra en metros

      const nlat1 = this.degreesToRadians(lat1);
      const nlat2 = this.degreesToRadians(lat2);
      const nlon1 = this.degreesToRadians(lon1);
      const nlon2 = this.degreesToRadians(lon2);

      const dLat = nlat2-nlat1;
      const dLon = nlon2-nlon1;

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(nlat1) *
          Math.cos(nlat2) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadius * c;

      return distance;
    },
       
 // ---------------------------- Navegación ----------------------------- 
    navigationToCancel(){
      this.$router.push("/operator/flights")
    },
    
 // ------------------------------ Filtrado -----------------------------
    filterNumbers() {
      // Verificar si this.newFlight.radius es una cadena
      if (typeof this.newFlight.radius === 'string') {
          // Eliminar caracteres no numéricos excepto los números
          this.newFlight.radius = this.newFlight.radius.replace(/[^0-9]/g, '');
      }
      
      // Verificar si this.newFlight.altitude es una cadena
      if (typeof this.newFlight.altitude === 'string') {
          // Eliminar caracteres no numéricos excepto los números
          this.newFlight.altitude = this.newFlight.altitude.replace(/[^0-9]/g, '');
      }
    },


  }
}
</script>

<style scoped>

.map-box{
  padding: 10px;
  width:100%;
  height:30vh;
  margin-bottom: 20px;
}

.map-container{
  width: 100%;
  height: 100%;
}


</style>
