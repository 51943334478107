<template>
  <div class="page">
    <div class="container" style="margin-top: 35px;">
      <v-container>
        <v-row>
          <h1>Operadoras para las que trabajo</h1>
        </v-row>
        <v-row class="table-container">
          <v-table class="table-100">
            <thead>
              <tr>
                <th @click="sortByColumn('operator.operator.name')">
                  <span>
                    Nombre operadora
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'operator.operator.name' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'operator.operator.name' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('operator.operator.noperator')">
                  <span>
                    Numero Operadora
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'operator.operator.noperator' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'operator.operator.noperator' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('operator.operator.phone')">
                  <span>
                    Teléfono
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'operator.operator.phone' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'operator.operator.phone' && sortOrder === -1 }"></i>
                  </span>
                </th>
                <th @click="sortByColumn('operator.operator.address')">
                  <span>
                    Dirección
                    <i class="mdi mdi-chevron-up " :class="{ 'color-icon': sortColumn === 'operator.operator.address' && sortOrder === 1 }"></i>
                    <i class="mdi mdi-chevron-down icon-ord" :class="{ 'color-icon': sortColumn === 'operator.operator.address' && sortOrder === -1 }"></i>
                  </span>
                </th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="operator in operatorList" :key="operator.operatorID" class="tr1">
                <td >{{ operator.operator.name }}</td>
                <td >{{ operator.operator.noperator }}</td>
                <td >{{ operator.operator.phone }}</td>
                <td >{{ operator.operator.address }}</td>

              </tr>
            </tbody>
          </v-table>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <button @click="previousPage" :disabled="pagination.page === 1">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span>Página {{ pagination.page }}/{{ totalPages }}</span>
          <button @click="nextPage" :disabled="pagination.page === totalPages">
            <i class="mdi mdi-chevron-right"></i>
          </button>
        </v-row>

      </v-container>
    

    
  </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import flightService  from '@/services/flightService';
import pilotsService from '@/services/pilotsService';



export default {
  name: 'FlightsPage',
  computed: {
    ...mapState('auth', ['token', 'roleID']),

    totalPages() {
      return Math.ceil(this.operatorList.length / this.pagination.rowsPerPage);
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
    endIndex() {
      return this.pagination.page * this.pagination.rowsPerPage;
    },
    pagedUASList() {
      return this.operatorList.slice(this.startIndex, this.endIndex);
    },
  }, 
  data() {
    return {
      operatorList: [],

      pagination: {
        rowsPerPage: 10,
        page: 1
      },

      sortColumn: '',
      sortOrder: 1, 

    };
  },
  mounted(){
    this.fetchOperators();
  },
  methods: {
    ...mapActions('auth',["logOut"]),
    async fetchOperators() {
      try {
        this.operatorList = await pilotsService.getOperators(this.token, this.roleID);

      } catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login");
        }
        else{
        console.error('Error al obtener datos de vuelos:', error);
        }
      }
    },


    formatIcon(value) {
      return value === 1 ? "✔" : "✘";
    },

    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },



    async deleteFlight(flightID){
      try{
        await flightService.remove(this.token, this.userID, flightID);
        this.fetchFlights();
      }catch(error){
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.$router.push("/login");
        }
        else{
          console.error('Error al eliminar el vuelo:', error);
        }
      }
    },

    navigateToFlightDetails(flightID) {
      this.$router.push(`/admin/flight/${flightID}`);
    },

    flightstatus(status){
      switch (status) {
        case 0:
          return "pre-vuelo sin restricciones";
        case 1:
          return "pre-vuelo autorizado";
        case 2:
          return "no autorizado";
        case 3:
          return "pendiente de autorización";
        case 4:
          return "en vuelo";
        case 5:
          return "finalizado";
        default:
          return "Estado desconocido";
      }
    },

    async sortByColumn(columnName) {
      if (this.sortColumn === columnName) {
        this.sortOrder *= -1;
      } else {
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }

      this.operatorList.sort((a, b) => {
        const columnParts = columnName.split('.');
        let aValue = a;
        let bValue = b;

        for (const part of columnParts) {
          aValue = aValue[part];
          bValue = bValue[part];
        }

        if (aValue < bValue) {
          return -this.sortOrder;
        } else if (aValue > bValue) {
          return this.sortOrder;
        }
        return 0;
      });
    },


  }
}
</script>

<style scoped>

</style>
