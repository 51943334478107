<template>
  <div class="page">
    <v-container>
      <v-row>
        <v-col class="ma-r-30"><div class="container">
          <v-row>
            <v-col  class="ma-tn-30">
              <i class="mdi mdi-earth dashboard-icon"></i>
            </v-col>
            <v-col>
              <h3 class="text-right">Vuelos</h3>
              <h2 class="text-right">{{ this.animated.flights -1}}</h2>
            </v-col>
          </v-row>
          <div class="line-1"></div>
        </div></v-col>
        <v-col class="ma-r-30"><div class="container">
          <v-row>
            <v-col  class="ma-tn-30">
              <i class="mdi mdi-calendar-clock dashboard-icon"></i>
            </v-col>
            <v-col>
              <h3 class="text-right">Proximos Vuelos</h3>
              <h2 class="text-right">{{ this.animated.future -1}}</h2>
            </v-col>
          </v-row>
          <div class="line-1"></div>
        </div></v-col>
        <v-col class="ma-r-30"><div class="container">
          <v-row>
            <v-col  class="ma-tn-30">
              <i class="mdi mdi-quadcopter dashboard-icon"></i>
            </v-col>
            <v-col>
              <h3 class="text-right">UAS</h3>
              <h2 class="text-right">{{ this.animated.uas -1}}</h2>
            </v-col>
          </v-row>
          <div class="line-1"></div>
        </div></v-col>
        <v-col><div  class="container">
          <v-row>
            <v-col  class="ma-tn-30">
              <i class="mdi mdi-account-group dashboard-icon"></i>
            </v-col>
            <v-col>
              <h3 class="text-right">Pilotos</h3>
              <h2 class="text-right">{{ this.animated.pilots -1}}</h2>
            </v-col>
          </v-row>
          <div class="line-1"></div>
        </div></v-col>
      </v-row>
      <v-row>
          <v-col cols="4" class="ma-r-30">
            <div  class="container">
                <h2 style="margin-left:15px">Operador</h2>
                <v-table density="compact">
                  <tbody>
                    <tr>
                      <th>Nombre Operador:</th>
                      <td>{{ this.operator.name }}</td>
                    </tr>
                    <tr>
                      <th>Número Operador:</th>
                      <td>{{ this.operator.noperator }}</td>
                    </tr>
                    <tr>
                      <th>Dirección:</th>
                      <td>{{ this.operator.address }}</td>
                    </tr>
                    <tr>
                      <th>CP:</th>
                      <td>{{ this.operator.cp }}</td>
                    </tr>
                    <tr>
                      <th>Ciudad:</th>
                      <td>{{ this.operator.city }}</td>
                    </tr>
                    <tr>
                      <th>Provincia:</th>
                      <td>{{ this.operator.state }}</td>
                    </tr>
                    <tr>
                      <th>Telefono:</th>
                      <td>{{ this.operator.phone }}</td>
                    </tr>
                  </tbody>
                </v-table>
            </div>
          </v-col>
          <v-col>
            <div class="container">
              <v-row><h2>Proximo vuelo</h2></v-row>
              <v-row style="margin-top:-20px;">
                <v-col>
                  <div class="container-2">
                        <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <v-table density="compact" class="v-table-trans" >
                          <tbody>
                            <tr>
                              <th>Inicio</th>
                              <td>{{ formatDateTime(futureFlights[currentIndex].start)}}</td>
                            </tr>
                            <tr>
                              <th>Fin</th>
                              <td>{{formatDateTime(futureFlights[currentIndex].end)   }}</td>
                            </tr>
                            <tr>
                              <th>Piloto</th>
                              <td>{{ futureFlights[currentIndex].pilot.name + " " + futureFlights[currentIndex].pilot.surname }}</td>
                            </tr>
                            <tr>
                              <th>UAS</th>
                              <td>{{ futureFlights[currentIndex].uas.model.manufacture + " " + futureFlights[currentIndex].uas.model.model + " (" + futureFlights[currentIndex].uas.sn + ") " }}</td>
                            </tr>
                            <tr>
                              <th>Estado</th>
                              <td><v-alert v-if="this.flightState()==1" type="info" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==2" type="warning" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==0" type="error" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert>
                              <v-alert v-if="this.flightState()==3" type="success" density="compact" class="alert">{{ futureFlights[currentIndex].states[futureFlights[currentIndex].states.length-1]?.state}}</v-alert></td>
                            
                            </tr>
                          </tbody>
                        </v-table>
                      </v-row>
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <button class="btn bgreen" v-if="this.readyToFly()" @click="startFlight()">Iniciar Vuelo</button>
                        <button class="btn bred" v-if="this.inFly()" @click="endFlight()">Finalizar Vuelo</button>
                        <button class="btn">Contactar</button>
                      </v-row>
                      <!-- Iconos para cambiar el índice de los vuelos -->
                      <v-row v-if="futureFlights.length > 0" class="d-flex justify-center align-center">
                        <button @click="previousFlight"><i class="mdi mdi-chevron-left"></i></button>
                        {{ currentIndex+1 + "/" + futureFlights.length }}
                        <button @click="nextFlight"><i class="mdi mdi-chevron-right"></i></button>
                      </v-row>
                      <v-row v-else>
                        <h2>No hay vuelos programados</h2>
                      </v-row>
                  </div>
                </v-col>
                <v-col cols="7">
                  <div class="map-box">
                    <div class="map-container" ref="mapContainer"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
      </v-row>


      <v-dialog v-model="contactVisible" style="width:500px"> 
        <v-card class="dialog-card">
          <v-card-title class="text-center pdf-title d-flex justify-space-between">
            Contactos
            <v-btn class="close-icon" icon @click="contactVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          <v-card-text>
            <v-table>
              <tbody>
                <tr><td>Guardia Civil</td> <td>062</td></tr>
                <tr><td>Policia Local</td> <td>091</td></tr>
                <tr><td>Policia Nacional</td> <td>092</td></tr>
                <tr><td>Emergencias</td> <td>112</td></tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import { mapState, mapActions } from 'vuex';
import mapService from '@/services/mapService'; 
import flightService from '@/services/flightService';
import operatorService from '@/services/operatorService';


export default {
  name: 'DashboardPage',  
  computed: {
    ...mapState('auth', ['token', 'roleID'])
  }, 
  data(){
    return{
      animated:{flights:0, nflights:0, future:0, nfuture:0, uas:0, nuas:0, pilots:0, npilots:0},

      isNext: false,
      futureFlights: [],
      currentIndex: 0,
      contactVisible: false,

      circle: null,
      marker: null,

      operator:[],
    }
  },
  async mounted(){
    await this.getAnimated();
    await this.initializeMap();
    await this.fetchFutureFlights();


    const intervalId = setInterval(() => {
      if (this.animated.flights > this.animated.nflights) {
        clearInterval(intervalId); 
      }else{
        this.animated.flights += 1;
      }
      
    }, 200);

    const intervalId2 = setInterval(() => {
      if (this.animated.uas > this.animated.nuas) {
        clearInterval(intervalId2); 
      }else{
        this.animated.uas += 1; 
      }
    }, 200);

    const intervalId3 = setInterval(() => {
      if (this.animated.pilots > this.animated.npilots) {
        clearInterval(intervalId3); 
      }else{
        this.animated.pilots += 1;
      }
    }, 200);

    const intervalId4 = setInterval(() => {
      if (this.animated.future > this.animated.nfuture) {
        clearInterval(intervalId4); 
      }else{
        this.animated.future += 1;
      }
    }, 200);
  },
  methods:{
    ...mapActions('auth',["logOut"]),


    /* 
    ------- Mapa -------
    inizializeMap()
    drawOperation()
    drawZones()    

    ------- Vuelos -------
    fetchFutureFlights()

    previousFlight()
    nextFlight()

    readyToFly()
    inFly()
    flightState()

    startFlight()
    endFlight()

    ------- Fecha y Hora -------
    formatDateTime(dateString)
    formatDate(dateString)
    formatTime(dateString)


    ------- Animaciónes -------
    getAnimated()

    ------- Dialogos -------
    openDialog()
    closeDialog()

    */



    // --------------------------------- MAPA  --------------------------------- 
    async initializeMap(){
      const asturiasCoords = [43.4, -5.8593];

      this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView(asturiasCoords, 9);
      this.map.invalidateSize();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'UASDoc',
        maxZoom: 19,
      }).addTo(this.map);

      await this.drawZones();
    },

    async drawOperation(index){
      this.map.setView([this.futureFlights[index].lat, this.futureFlights[index].lng],15);

      if (this.circle) {
        this.map.removeLayer(this.circle);
      }

      this.circle = L.circle([this.futureFlights[index].lat, this.futureFlights[index].lng], {
        color: 'blue',
        fillOpacity: 0.2,
        radius: this.futureFlights[0].radius,
      }).addTo(this.map);
    },

    async drawZones() {
      try {
        const zones = await mapService.getZones(this.token);

        zones.forEach(zone => {
          L.circle([zone.lat, zone.lng], {
            color: 'purple',
            fillOpacity: 0.2,
            radius: zone.radius,
          }).addTo(this.map);
        });
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
          console.error('Error al dibujar las zonas:', error);
        }
      }
    },


    // --------------------------------- FLIGHTS  --------------------------------- 

    async fetchFutureFlights(){
      try{
        this.futureFlights =[];
        this.futureFlights = await operatorService.getFutureFlights(this.token, this.roleID);
        if (this.futureFlights.length>0){
          this.drawOperation(0);
        }
      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
          console.error('Error al obtener si existe futuro vuelo:', error);
        }
      }
    },

    
    previousFlight() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.drawOperation(this.currentIndex);
      }
    },
  
    nextFlight() {
      if (this.currentIndex < this.futureFlights.length - 1) {
        this.currentIndex++;
        this.drawOperation(this.currentIndex);
      }
    },

    readyToFly() {
      const currentDate = new Date();
      const flightStartDate = new Date(this.futureFlights[this.currentIndex].start);
      const flighEndtDate = new Date(this.futureFlights[this.currentIndex].end);

      const timeDifferenceInMillisecondsStart = flightStartDate.getTime() - currentDate.getTime();
      const timeDifferenceInHoursStart = timeDifferenceInMillisecondsStart / (1000 * 60 * 60); 
            
      const timeDifferenceInMillisecondsEnd = flighEndtDate.getTime() - currentDate.getTime();
      const timeDifferenceInHoursEnd = timeDifferenceInMillisecondsEnd / (1000 * 60 * 60); 


      if (
        (this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length - 1].state === "Listo para volar" ||
        this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length - 1].state === "Vuelo Finalizado" ) &&
        timeDifferenceInHoursStart <= 2 && timeDifferenceInHoursEnd >=-2
      ) {
        return true;
      } else {
        return false;
      }
    },


    inFly(){
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length - 1].state == "En vuelo"){
        
        const currentDate = new Date();
        const flightDate = new Date(this.futureFlights[this.currentIndex].end);

        // Compara solo el día y permite un margen de 2 horas
        const timeDifferenceInMilliseconds = currentDate.getTime() - flightDate.getTime();
        const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convertir a horas

        if(timeDifferenceInHours >= 2 ){
          console.log("remove")
        }
        return true;
      }
      else{
        return false;
      }
    },

    flightState(){
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "Listo para volar"){
        return 1;
      }
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "Pendiente de autorización") {
        return 2;
      }
      if(this.futureFlights[this.currentIndex].states[this.futureFlights[this.currentIndex].states.length-1]?.state == "En vuelo"){
        return 3;
      } 
      else {
        return 0;
      }

    },

    async startFlight(){
      await flightService.createState(this.token, this.futureFlights[this.currentIndex].flightID, "En vuelo");
      await this.fetchFutureFlights();
    },


    async endFlight(){
      await flightService.createState(this.token, this.futureFlights[this.currentIndex].flightID, "Vuelo Finalizado");
      await this.fetchFutureFlights();
    },


    // --------------------------------- Fecha y Hora  --------------------------------- 
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('es-ES', options);
    },

    formatTime(dateString) {
      const date = new Date(dateString);
      const options = { hour: '2-digit', minute: '2-digit' };
      return date.toLocaleTimeString('es-ES', options);
    },

    

    // --------------------------------- Animaciones  --------------------------------- 

    async getAnimated(){
      try{
        const flights = await operatorService.getFlights(this.token, this.roleID);
        this.animated.nflights = flights.length;
        
        const pilots = await operatorService.getPilots(this.token, this.roleID);
        this.animated.npilots = pilots.length;

        const uas = await operatorService.getUas(this.token, this.roleID);
        this.animated.nuas = uas.length;
        
        this.operator = await operatorService.get(this.token, this.roleID);

      }catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.error == 'Token inválido') {
          console.log('Token Expirado - redireccion a pagina de acceso',);
          this.logOut();
          this.$router.push("/login")
        }
        else{
          console.error('Error al obtener si existe futuro vuelo:', error);
        }
      }
    },


    // --------------------------------- Dialogos  --------------------------------- 

    openDialog() {
      this.contactVisible = true;
    },    
    closeDialog() {
      this.contactVisible = false;
    },




  }
}

</script>

<style>
.dashboard-icon{
  font-size: 80px;
  color:#2E838C;
  }

  .alert{
    font-size: 12px;
  }

  .mdi-check-circle{
    font-size: 20px !important;
  }

  .mdi-check-circle:hover{
    color:white !important;
  }
</style>
