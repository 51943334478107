import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/IndexPage.vue";
import Login from "@/views/LoginPage.vue";
import Register from "@/views/RegisterPage.vue";


import adminDashboard from "@/views/Admin/DashboardPage.vue"
import adminMap from "@/views/Admin/MapsPage.vue"
import adminUAS from "@/views/Admin/UASPage.vue"
import adminFlights from "@/views/Admin/FlightsPage.vue"
import adminPilots from "@/views/Admin/PilotsPage.vue"
import adminOperators from "@/views/Admin/OperatorsPage.vue"
import adminZones from "@/views/Admin/ZonesPage.vue"

import zoneDashboard from "@/views/Zone/DashboardPage.vue"
import zoneMap from "@/views/Zone/MapsPage.vue"
import zoneFlights from "@/views/Zone/FlightsPage.vue"
import zonePilots from "@/views/Zone/PilotsPage.vue"
import zoneOperators from "@/views/Zone/OperatorsPage.vue"

import opDashboard from "@/views/Operator/DashboardPage.vue"
import opMap from "@/views/Operator/MapsPage.vue"
import opUAS from "@/views/Operator/UASPage.vue"
import opFlights from "@/views/Operator/FlightsPage.vue"
import opPilots from "@/views/Operator/PilotsPage.vue"
import opMaintenance from "@/views/Operator/MaintenancePage.vue"
import opDocuments from "@/views/Operator/DocumentsPage.vue"
import opInfoFlight from "@/views/Operator/InfoFlightPage.vue";
import newFlight from "@/views/Operator/NewFlightPage.vue"
import newFlightCoords from "@/views/Operator/NewFlightPage.vue"

import pilotDashboard from "@/views/Pilot/DashboardPage.vue"
import pilotMap from "@/views/Pilot/MapsPage.vue"
import pilotFlights from "@/views/Pilot/FlightsPage.vue"
import pilotInfoFlight from "@/views/Pilot/InfoFlightPage.vue"
import pilotOperators from "@/views/Pilot/OperatorsPage.vue"

import store from '../store';


const routes = [

  // COMÚN -----------------------------------------

  {
    path: "/",
    name: "Inicio",
    component: Home,
    meta: { 
      requiresAuth: false,
    },
  },  
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { 
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { 
      requiresAuth: false,
    },
  },

  

  
  // ADMIN -----------------------------------------
  {
    path: '/admin',
    name: 'adminDashboard',
    component: adminDashboard,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/map',
    name: 'adminMap',
    component: adminMap,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/uas',
    name: 'adminUAS',
    component: adminUAS,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/flights',
    name: 'adminFlights',
    component: adminFlights,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/pilots',
    name: 'adminPilots',
    component: adminPilots,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/operators',
    name: 'adminOperators',
    component: adminOperators,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },
  {
    path: '/admin/zones',
    name: 'adminZones',
    component: adminZones,
    meta: { 
      requiresAuth: true,
      role: 'admin' 
    },
  },

  // ZONAS ----------------------------------------------
  {
    path: '/zone/dashboard',
    name: 'zoneDashboard',
    component: zoneDashboard,
    meta: { 
      requiresAuth: true,
      role: 'zone' 
    },
  },
  {
    path: '/zone/map',
    name: 'zoneMap',
    component: zoneMap,
    meta: { 
      requiresAuth: true,
      role: 'zone' 
    },
  },
  {
    path: '/zone/flights',
    name: 'zoneFlights',
    component: zoneFlights,
    meta: { 
      requiresAuth: true,
      role: 'zone' 
    },
  },
  {
    path: '/zone/pilots',
    name: 'zonePilots',
    component: zonePilots,
    meta: { 
      requiresAuth: true,
      role: 'zone' 
    },
  },
  {
    path: '/zone/operators',
    name: 'zoneOperators',
    component: zoneOperators,
    meta: { 
      requiresAuth: true,
      role: 'zone' 
    },
  },

  // OPERADOR -----------------------------------------
  
  {
    path: '/operator/dashboard',
    name: 'opDashboard',
    component: opDashboard,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/map',
    name: 'opMap',
    component: opMap,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/uas',
    name: 'opUAS',
    component: opUAS,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/flights',
    name: 'opFlights',
    component: opFlights,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/pilots',
    name: 'opPilots',
    component: opPilots,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/maintenance',
    name: 'opMaintenance',
    component: opMaintenance,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/documents',
    name: 'opDocuments',
    component: opDocuments,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: "/operator/flight/:flightID",
    name: "opInfoFlight",
    component: opInfoFlight,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },
  {
    path: '/operator/flight/new',
    name: 'newFlight',
    component: newFlight,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },

  {
    path: '/operator/flight/new/:coords',
    name: 'newFlightCoords',
    component: newFlightCoords,
    meta: { 
      requiresAuth: true,
      role: 'operator' 
    },
  },

  // PILOTO -----------------------------------------

  {
    path: '/pilot/dashboard',
    name: 'pilotDashboard',
    component: pilotDashboard,
    meta: { 
      requiresAuth: true,
      role: 'pilot' 
    },
  },
  {
    path: '/pilot/map',
    name: 'pilotMap',
    component: pilotMap,
    meta: { 
      requiresAuth: true,
      role: 'pilot' 
    },
  },
  {
    path: '/pilot/flights',
    name: 'pilotFlights',
    component: pilotFlights,
    meta: { 
      requiresAuth: true,
      role: 'pilot' 
    },
  },

  {
    path: "/pilot/flight/:flightID",
    name: "pilotInfoFlight",
    component: pilotInfoFlight,
    meta: { 
      requiresAuth: true,
      role: 'pilot' 
    },
  },

  {
    path: '/pilot/operators',
    name: 'pilotOperators',
    component: pilotOperators,
    meta: { 
      requiresAuth: true,
      role: 'pilot' 
    },
  },
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Ejemplo de redirección en el guard de ruta
router.beforeEach((to, from, next) => {
  
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (store.getters['auth/isAuthenticated']) {
      const userRole = store.getters['auth/getRole'];
      const requieredRole = to.meta.role;
      if(userRole === requieredRole){
        next();
      }
      else{
        next('access-denied');
      }

    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});


export default router;